import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">{payload.value}</text>
        </g>
    );
};

const ImpactGraph = ({ graphData }) => (
    <ResponsiveContainer width="100%" height={400}>
        <LineChart data={graphData} margin={{ top: 50, right: 30, left: 20, bottom: 30 }}>
            <CartesianGrid vertical={false} horizontal={true} />
            <XAxis dataKey="month" label={{ value: 'Months', position: 'insideBottom', offset: -18 }} tick={<CustomizedAxisTick />} />
            <YAxis axisLine={false} label={{ value: 'Population Served', offset: -10, angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            {/* <Legend layout="horizontal" verticalAlign="top" align="center" /> */}
            <Line type="monotone" dataKey="totalCount" stroke="#8884d8" strokeWidth={3} dot={false} />
            <Line type="monotone" dataKey="activeCustomers" stroke="#82ca9d" strokeWidth={3} dot={false} />
        </LineChart>
    </ResponsiveContainer>
);

export default ImpactGraph;





