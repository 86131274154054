import { Col, Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOperationStaff } from '../../../../features/fetch/fetchSlice';
import { createData } from '../../../../utils';
import GradingComponent from '../../../../components/GradingComponent';

export default function StaffInfoDetails() {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);
	const { projectScoreStepOne } = useSelector((state) => state.fetch);

	const { loading, operationStaffs } = useSelector((state) => state.fetch);

	async function handleFetch() {
		await dispatch(fetchOperationStaff(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj, operationStaffs]);

	useEffect(() => {
		handleFetch();
	}, []);

	const columns = [
		{
			title: 'Type of staff',
			dataIndex: 'osStaff',
		},
		{
			title: 'Number',
			dataIndex: 'osNumber',
		},
		{
			title: 'Type of contract',
			dataIndex: 'osContract',
		},
	];

	const arr1 = [createData('', '', 'operationsScores', 5)];

	return (
		<>
			<div className='w-full mt-[2.75rem]'>
				<Table
					loading={loading}
					pagination={{
						defaultPageSize: 7,
						hideOnSinglePage: true,
						pageSizeOptions: [7, 15, 50, 100],
					}}
					columns={columns}
					dataSource={operationStaffs}
				/>

				<Col className='mt-[1rem]' span={24}>
					{arr1?.map((item) => {
						return (
							<GradingComponent
								handleFetch={handleFetch}
								arrayList={projectScoreStepOne}
								data={item.data}
								itemKey={item.itemKey}
								itemName={item.itemName}
								isFile={item.isFile}
								overalScore={item.overalScore}
							/>
						);
					})}
				</Col>
			</div>
		</>
	);
}
