import React from 'react';
import { getInitials } from '../../../../utils';
import moment from 'moment/moment';

const SingleProject = ({ activeId, item, handleVActiveProjectChange }) => {
	function getRandomColor() {
		let r = Math.floor(Math.random() * 256);
		let g = Math.floor(Math.random() * 256);
		let b = Math.floor(Math.random() * 256);

		let yiq = (r * 299 + g * 587 + b * 114) / 1000;

		if (yiq > 128) {
			r = Math.floor(r * 0.7);
			g = Math.floor(g * 0.7);
			b = Math.floor(b * 0.7);
		}
		return `rgb(${r}, ${g}, ${b})`;
	}

	return (
		<button
			onClick={() => handleVActiveProjectChange(item)}
			className='flex flex-col  border-[1px] p-[1.31rem]'
			style={{
				backgroundColor: activeId === item?.pjdId ? '#fff' : '#F8F9FA',
				border: '1px solid #E6EAEF',
			}}>
			<div className='flex items-center'>
				<div
					style={{ backgroundColor: getRandomColor() }}
					className='project_tiny_card'>
					{getInitials(item?.accTradeName)}
				</div>
				<div className='ml-[.56rem] proj_tiny_card_header_txt'>
					{item?.accTradeName}
				</div>
			</div>
			<div className='flex flex-row items-center my-[.5rem]'>
				{item?.pjdDesc === 'APPROVED' ? (
					<div className='pro_approval_txt'>Approved</div>
				) : (
					<div className='!text-[#ED8731] pro_approval_txt'>
						Pending Approval
					</div>
				)}

				<div className='ml-[.44rem] text-[0.8125rem]'>
					| {moment(item?.pjdCreatedDate).format('LL')}
				</div>
			</div>
			{}
			<div className='proj_paid_status_txt'>
				{item?.pjdStatus === 'PAID' ? 'Paid' : 'Not paid'}
			</div>
		</button>
	);
};

export default SingleProject;
