import { Descriptions } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formatPath, previewPaper } from '../../../utils';
import ViewFileButton from '../../../components/ViewFileButton';

export default function BusinessDetailStep({ next, prev }) {
	const { newProjectObj } = useSelector((state) => state.obj);

	const {
		accTradeName,
		accCounty,
		accSubCounty,
		accRole,
		accServices,
		projBankStatementFile,
	} = newProjectObj;

	useEffect(() => {}, [newProjectObj]);

	const items = [
		{
			key: '1',
			label: 'Water Project',
			children: accTradeName ?? 'N/A',
		},
		{
			key: '2',
			label: 'Role on the project',
			children: accRole ?? 'N/A',
		},
		{
			key: '3',
			label: 'Financial Statements ',
			children: (
				<button
					onClick={() => (projBankStatementFile?.length > 10 ? previewPaper(projBankStatementFile) : null)}
					className='flex items-center'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='19'
						height='13'
						viewBox='0 0 19 13'
						fill='none'>
						<path
							d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
							fill='#0170BD'
						/>
					</svg>
					<span className='ml-[.44rem] table_view_txt'>{formatPath(projBankStatementFile)}</span>
				</button>
			),
		},
		{
			key: '4',
			label: 'County',
			children: accCounty ?? 'N/A',
		},
		{
			key: '5',
			label: 'Sub-County',
			children: accSubCounty ?? 'N/A',
		},
		{
			key: '6',
			label: 'Services offered',
			children: accServices ?? 'N/A',
		},
	];
	return (
		<>
			<div className='info_desc_card'>
				<Descriptions
					column={1}
					colon={false}
					title='Business Details'
					items={items}
				/>
			</div>

			<div className='mt-[8.75rem] flex flex-col'>
				<button onClick={() => next()} className='cstm-btn'>
					Next
				</button>
				<button onClick={() => prev()} className='border-btn mt-[.75rem]'>
					Previous
				</button>
			</div>
		</>
	);
}
