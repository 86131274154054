import { Input, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../features/save/saveSlice';
import ViewFileButton from './ViewFileButton';

export default function GradingComponent({
	handleFetch,
	arrayList,
	data,
	itemKey,
	itemName,
	overalScore,
	isFile,
}) {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);

	const [formObj, setformObj] = useState({});

	function handleActiveObj() {
		let scoreObj = arrayList?.find(
			(item) =>
				String(item?.scMethod).toUpperCase() === String(itemName).toUpperCase()
		);
		setformObj(scoreObj);
	}

	const handleSave = async (data) => {
		let saveObj = {
			...data,
			scId: formObj?.scId,
			scProjId: projectObj?.projId,
			url: '/usr/saveProjectScore.action?page=GOVERNANCE_INFO',
		};
		await dispatch(save(saveObj));
	};

	async function handleChange(field, value) {
		setformObj((prev) => ({
			...prev,
			[field]: value,
		}));
	}

	async function handleBlur() {
		await handleSave(formObj);
		await handleFetch();
	}

	useEffect(() => {
		handleActiveObj();
	}, [itemName, arrayList, data]);

	return (
		<>
			<div className='flex flex-col gap-y-[.56rem] w-full px-[2rem] mb-[2.17rem]'>
				<div className='flex items-center gap-[.2rem] w-full'>
					<span className='score_text'>{itemKey} - </span>
					{isFile ? (
						<ViewFileButton file={data} />
					) : (
						<span className='score_text !italic'>{data}</span>
					)}
				</div>
				<Input
					value={formObj?.scUsrComment}
					onBlur={handleBlur}
					onChange={(e) => handleChange('scUsrComment', e.target.value)}
					placeholder='Add a comment....'
					className='proj_score_blue_input'
					prefix={
						<svg
							width='1'
							height='28'
							viewBox='0 0 1 28'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<line
								x1='0.5'
								y1='0.178711'
								x2='0.5'
								y2='27.1787'
								stroke='#0170BD'
							/>
						</svg>
					}
				/>
				<div className='flex items-end gap-x-[2.43rem]'>
					<span className='sys_score_text w-fit'>
						System score: {formObj?.scScore}/{overalScore}
					</span>
					<div className='flex items-end gap-x-[.1rem]'>
						<span className='ta_score_txt w-auto'>Your Score:</span>
						<InputNumber
							value={formObj?.scUsrScore}
							onBlur={handleBlur}
							onChange={(e) => handleChange('scUsrScore', e)}
							max={overalScore}
							className='proj_score_under_score_input'
						/>
					</div>
				</div>
			</div>
		</>
	);
}
