import { Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPipeExtents } from '../../../../features/fetch/fetchSlice';

export default function PipelineExtentDetails() {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);

	const { loading, pipeExtents } = useSelector((state) => state.fetch);

	async function handleFetch() {
		await dispatch(fetchPipeExtents(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj, pipeExtents]);

	useEffect(() => {
		handleFetch();
	}, []);

	const columns = [
		{
			title: 'Size (diameter)',
			dataIndex: 'peSize',
		},
		{
			title: 'Material',
			dataIndex: 'peMaterial',
		},
		{
			title: 'Total length (m)',
			dataIndex: 'peTotalLength',
		},
	];

	return (
		<>
			<div className='w-full mt-[2.75rem]'>
				<Table
					loading={loading}
					pagination={{
						defaultPageSize: 7,
						hideOnSinglePage: true,
						pageSizeOptions: [7, 15, 50, 100],
					}}
					columns={columns}
					dataSource={pipeExtents}
				/>
			</div>
		</>
	);
}
