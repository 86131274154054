import { Collapse } from 'antd';
import GovernanceDetails from './governance_panels/GovernanceDetails';
import AnnualMeetingDetails from './governance_panels/AnnualMeetingDetails';
import { useEffect, useState } from 'react';
import ManagementDetails from './governance_panels/ManagementDetails';
import ManagementTools from './governance_panels/ManagementTools';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectScoreStepOne } from '../../../features/fetch/fetchSlice';
import TextArea from 'antd/es/input/TextArea';
import { save } from '../../../features/save/saveSlice';

const expandIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M1.645 0L7 5.56275L12.355 0L14 1.71255L7 9L0 1.71255L1.645 0Z'
			fill='#0170BD'
		/>
	</svg>
);

const activeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M12.355 9L7 3.43725L1.645 9L0 7.28745L7 0L14 7.28745L12.355 9Z'
			fill='#0170BD'
		/>
	</svg>
);

const mainIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='8'
		height='13'
		viewBox='0 0 8 13'
		fill='none'>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 9.34766)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 9.34766)'
			fill='#0170BD'
		/>
	</svg>
);

export default function GovernanceEditableStep({ next, projectObj, handleFetchScoreSteps }) {
	const dispatch = useDispatch();

	const { projectScoreStepOne, projectScores } = useSelector(
		(state) => state.fetch
	);

	let stepComment = projectScores?.find(
		(item) => item?.sctStep === 'GOVERNANCE_INFO'
	);

	const total = projectScoreStepOne?.reduce(
		(acc, val) => acc + Number(val?.scUsrScore),
		0
	);

	const [activeKey, setactiveKey] = useState(['0']);
	const [comment, setcomment] = useState(stepComment?.sctComment ?? '');

	const onChange = (key) => {
		setactiveKey(key);
	};

	async function handleFetch() {
		await dispatch(fetchProjectScoreStepOne(projectObj?.projId));
	}

	const handleSave = async () => {
		// setcomment(value)
		const saveObj = {
			sctStep: 'GOVERNANCE_INFO',
			sctProjId: projectObj.projId,
			sctComment: comment,
			sctTotalScore: total,
			url: '/usr/saveScoreStep.action',
		};

		await dispatch(save(saveObj));
		await handleFetchScoreSteps()
	};

	const items = [
		{
			key: '0',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Governance information</span>
				</div>
			),
			children: <GovernanceDetails handleFetch={handleFetch} />,
		},
	];

	const items2 = [
		{
			key: '1',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
						Annual general meeting information
					</span>
				</div>
			),
			children: <AnnualMeetingDetails handleFetch={handleFetch} />,
		},
	];

	const items3 = [
		{
			key: '2',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
						Details of management committee members/ directors
					</span>
				</div>
			),
			children: <ManagementDetails handleFetch={handleFetch} />,
		},
	];

	const items4 = [
		{
			key: '3',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Management tools</span>
				</div>
			),
			children: <ManagementTools handleFetch={handleFetch} />,
		},
	];

	useEffect(() => {
		handleFetch();
	}, []);

	useEffect(() => {}, [projectScores, projectScoreStepOne]);

	return (
		<>
			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items2}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items3}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items4}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='flex flex-col'>
				<label className='label_2'>Comments on above information</label>
				<TextArea
					prefix={
						<svg
							width='1'
							height='28'
							viewBox='0 0 1 28'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<line
								x1='0.5'
								y1='0.178711'
								x2='0.5'
								y2='27.1787'
								stroke='#0170BD'
							/>
						</svg>
					}
					placeholder='Add comment....'
					className='textarea_blue mt-[.69rem]'
					value={comment}
					rows={5}
					onBlur={handleSave}
					onChange={(e) => setcomment(e.target.value)}
				/>
			</div>

			<div className='w-full flex items-center justify-center gap-x-[11.67rem] mt-[10.23rem] mb-[5rem]'>
				<button>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='67'
						height='20'
						viewBox='0 0 67 20'
						fill='none'>
						<path
							d='M16.6944 12.6594L66.9453 12.6594V7.8386L16.6944 7.8386L16.6944 0.607388L-0.0001297 10.249L16.6944 19.8906V12.6594Z'
							fill='#BAD9ED'
						/>
					</svg>
				</button>

				<span className='panel_num_txt'>1/6</span>

				<button onClick={() => next()}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='68'
						height='20'
						viewBox='0 0 68 20'
						fill='none'>
						<path
							d='M50.6308 7.84059H0.379883V12.6614H50.6308V19.8926L67.3253 10.251L50.6308 0.609375V7.84059Z'
							fill='#0873B9'
						/>
					</svg>
				</button>
			</div>
		</>
	);
}
