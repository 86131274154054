import { useEffect, useRef, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import BreadCrumb from '../../layout/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { Steps } from 'antd';
import GovernanceEditableStep from './project_details_steps/GovernanceEditableStep';
import WaterSourceEditableStep from './project_details_steps/WaterSourceEditableStep';
import ApplicantParticularsEditableStep from './project_details_steps/ApplicantParticularsEditableStep';
import FinancialPositionEditableStep from './project_details_steps/FinancialPositionEditableStep';
import TypeOfFinancingEditableStep from './project_details_steps/TypeOfFinancingEditableStep';
import FinalVerdictEditableStep from './project_details_steps/FinalVerdictEditableStep';
import { fetchScoreStep } from '../../features/fetch/fetchSlice';

export default function ProjectDetailEditableView() {
	const dispatch = useDispatch()

	const { projectObj } = useSelector((state) => state.obj);

	const [current, setCurrent] = useState(0);

	const view = useRef();

	const next = () => {
		setCurrent(current + 1);
		view?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const prev = () => {
		setCurrent(current - 1);
		view?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const onChange = async (value) => {
		await setCurrent(value);
		await view?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	async function handleFetchScoreSteps() {
		await dispatch(fetchScoreStep(projectObj?.projId));
	}

	useEffect(() => {
		handleFetchScoreSteps();
	}, []);

	useEffect(() => {}, [projectObj]);

	const breadList = [
		{
			title: 'Home',
			href: '/',
		},
		{
			title: 'Screening',
			href: '/',
		},
		{
			title: projectObj?.accTradeName?.toUpperCase(),
		},
	];

	const steps = [
		{
			key: 0,
			title: 'Governance',
			content: <GovernanceEditableStep handleFetchScoreSteps={handleFetchScoreSteps} projectObj={projectObj} next={next} />,
		},
		{
			key: 1,
			title: (
				<span>
					Water source <br /> & infrastructure
				</span>
			),
			content: <WaterSourceEditableStep handleFetchScoreSteps={handleFetchScoreSteps} projectObj={projectObj} next={next} prev={prev} />,
		},
		{
			key: 2,
			title: (
				<span>
					Applicant <br /> particulars
				</span>
			),
			content: <ApplicantParticularsEditableStep handleFetchScoreSteps={handleFetchScoreSteps} projectObj={projectObj} next={next} prev={prev} />,
		},
		{
			key: 3,
			title: (
				<span>
					Summary of <br /> financial position
				</span>
			),
			content: <FinancialPositionEditableStep handleFetchScoreSteps={handleFetchScoreSteps} projectObj={projectObj} next={next} prev={prev} />,
		},
		{
			key: 4,
			title: (
				<span>
					Type of <br /> financing
				</span>
			),
			content: <TypeOfFinancingEditableStep handleFetchScoreSteps={handleFetchScoreSteps} projectObj={projectObj} next={next} prev={prev} />,
		},
		{
			key: 5,
			title: 'Final verdict',
			content: <FinalVerdictEditableStep handleFetchScoreSteps={handleFetchScoreSteps} prev={prev} />,
		},
	];

	const items = steps.map((item) => ({
		key: item.key,
		title: item.title,
	}));

	return (
		<div ref={view}>
			<PageHeader header={'Screening'} />
			<div className='mt-[.94rem]'>
				<BreadCrumb breadList={breadList} />
			</div>

			<div className='mt-[4.06rem]'>
				<Steps
					onChange={onChange}
					current={current}
					items={items}
					className='project-detail'
				/>
			</div>

			<div className='mt-[2.65rem]'>{steps[current].content}</div>
		</div>
	);
}
