import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../../features/save/saveSlice';
import { uploadFile } from '../../../../features/obj/objSlice';

export default function SpecialMinutePanel({ handleFetch }) {
	const dispatch = useDispatch();

	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);

	const [value, setValue] = useState(
		projectPreparationDetails?.wrSpecialMin ?? ''
	);
	const [file, setfile] = useState(
		projectPreparationDetails?.wrSpecialMinFile ?? ''
	);

	const handleSave = async () => {
		let saveObj = {
			wrId: projectPreparationDetails?.wrId,
			wrProjId: projectObj?.projId,
			wrSpecialMin: value,
			wrSpecialMinFile: file,
			url: '/usr/saveWaterResource.action',
		};
		await dispatch(save(saveObj));
		await handleFetch()
	};

	const onChange = async (e) => {
		setValue(e.target.value);
		await handleSave();
	};

	const handleFile = async (e) => {
		let file = e.target.files[0];
		const formData = new FormData();

		formData.append('file', file);
		const res = await dispatch(uploadFile(formData));

		setfile(res?.payload);
        await handleSave()
	};

	useEffect(() => {}, [projectObj, projectPreparationDetails]);

	return (
		<>
			<div className='flex flex-col px-[2rem] pb-[2rem]'>
				<Radio.Group onChange={onChange} value={value}>
					<Radio value='provided'>Provided</Radio>
					<Radio className='ml-[10.19rem]' value='notProvided'>
						Not Provided
					</Radio>
				</Radio.Group>

				<label className='cursor-pointer flex items-center mt-[1.75rem]' htmlFor='file'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='15'
						height='19'
						viewBox='0 0 15 19'
						fill='none'>
						<path
							d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
							fill='#0170BD'
						/>
					</svg>
					<input
						id='file'
						type='file'
						name='file'
						onChange={(e) => handleFile(e)}
						hidden
					/>
                    <span className='attach_file_text ml-[.3rem]'>{file ? file?.split("_")?.pop() : "Attach File"}</span>
				</label>
			</div>
		</>
	);
}
