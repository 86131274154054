import { Table, Tag } from 'antd';
// import BreadCrumb from '../../layout/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
import { capitalize } from '../../../../utils';
// import { formatMoney } from '../../utils';

const breadList = [
	{
		title: 'Home',
		href: '/',
	},
	{
		title: 'New Projects',
	},
];

const dummyData = [
	{
		result:
			'Increased access to safe Water, sanitation and IWRM services/products',
		indicator:
			'Total number of people reached with safe Water, sanitation and IWRM services/products',
		measurement: '3,000,000',
		status: 'target reached',
	},
	{
		result:
			'Local partners can sustain the WASH solution in the long-term (10-15 years)',
		indicator: 'Locally available technology',
		measurement: '2/3',
		status: 'target reached',
	},
	{
		result:
			'Local partners can sustain the WASH solution in the long-term (10-15 years)',
		indicator: 'Revenue vs O&M Costs ',
		measurement: '2/3',
		status: 'target reached',
	},
	{
		result:
			'Local partners have viable business model and/or ensured Long-term financial sustainability',
		indicator: 'Net Profit Margin',
		measurement: '2/3',
		status: 'target reached',
	},
];

const usrIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='31'
		height='31'
		viewBox='0 0 31 31'
		fill='none'>
		<g clip-path='url(#clip0_1_4022)'>
			<path
				d='M15.4997 2.58301C8.36967 2.58301 2.58301 8.36967 2.58301 15.4997C2.58301 22.6297 8.36967 28.4163 15.4997 28.4163C22.6297 28.4163 28.4163 22.6297 28.4163 15.4997C28.4163 8.36967 22.6297 2.58301 15.4997 2.58301ZM15.4997 6.45801C17.6438 6.45801 19.3747 8.18884 19.3747 10.333C19.3747 12.4772 17.6438 14.208 15.4997 14.208C13.3555 14.208 11.6247 12.4772 11.6247 10.333C11.6247 8.18884 13.3555 6.45801 15.4997 6.45801ZM15.4997 24.7997C12.2705 24.7997 9.41592 23.1463 7.74967 20.6405C7.78842 18.0701 12.9163 16.6622 15.4997 16.6622C18.0701 16.6622 23.2109 18.0701 23.2497 20.6405C21.5834 23.1463 18.7288 24.7997 15.4997 24.7997Z'
				fill='#0170BD'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_4022'>
				<rect width='31' height='31' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export default function MonitoringTable() {
	const { loading, newProjects } = useSelector((state) => state.fetch);

	const [value, setvalue] = useState('');

	// const arrayCopy = [...newProjects]
	//     ?.filter(
	//         (x) =>
	//             x?.projStatus === 'ACTIVE' ||
	//             x?.projStatus === 'DRAFT' ||
	//             x?.projStatus === 'REJECT'
	//     )
	//     ?.filter(
	//         (item) =>
	//             String(item?.accTradeName)
	//                 .toUpperCase()
	//                 .includes(String(value).toUpperCase()) ||
	//             String(item?.accCounty)
	//                 .toUpperCase()
	//                 .includes(String(value).toUpperCase())
	//     );

	useEffect(() => {}, [newProjects, value]);

	useEffect(() => {}, []);

	const columns = [
		{
			title: 'Result',
			dataIndex: 'result',
			render: (item) => (
				<div className='flex items-center'>
					{usrIcon}
					<span className='ml-[1.37rem]'>{item}</span>
				</div>
			),
		},
		{
			title: 'Indicator',
			dataIndex: 'indicator',
		},
		{
			// title: 'Performance',
			dataIndex: 'Measurement ',
			render: (item) => <span>{item}</span>,
		},
		{
			title: 'Measurement',
			dataIndex: 'measurement',
			render: (item) => <span>{item}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (item) => {
				let color = item === 'target reached' ? '#078247' : '#32CD32';

				return (
					<Tag color={color} key={item}>
						{capitalize(item)}
					</Tag>
				);
			},
		},
	];

	return (
		<>
			<div className='w-[100%] flex flex-col'>
				<div className='w-full mt-[2.75rem]'>
					<Table
						loading={loading}
						pagination={{
							defaultPageSize: 7,
							hideOnSinglePage: true,
							pageSizeOptions: [7, 15, 50, 100],
						}}
						columns={columns}
						dataSource={dummyData}
					/>
				</div>
			</div>
		</>
	);
}
