import { Col, Form, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../../features/save/saveSlice';
import { booleanData, eiaData } from '../../../../utils';
import { uploadFile } from '../../../../features/obj/objSlice';
import TextArea from 'antd/es/input/TextArea';

const selectIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11'
		height='7'
		viewBox='0 0 11 7'
		fill='none'>
		<path
			d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
			fill='#222222'
		/>
	</svg>
);

export default function EnvironmentalPanel({ handleFetch }) {
	const dispatch = useDispatch();

	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);

	const [value, setvalue] = useState(
		projectPreparationDetails?.wrProjectObjectors ?? ''
	);

	const handleSave = async (data) => {
		let saveObj = {
			...data,
			wrId: projectPreparationDetails?.wrId,
			wrProjId: projectObj?.projId,
			url: '/usr/saveWaterResource.action',
		};
		await dispatch(save(saveObj));
	};

	const handleFile = async (field, e) => {
		let file = e.target.files[0];
		const formData = new FormData();

		formData.append('file', file);

		const res = await dispatch(uploadFile(formData));
		let data = {
			[field]: res?.payload,
		};

		await handleSave(data);
		await handleFetch();
	};

	async function handleChange(field, value) {
		let data = {
			[field]: value,
		};
		await handleSave(data);
		await handleFetch();
	}

	useEffect(() => {}, [projectObj, projectPreparationDetails]);

	return (
		<>
			<Row className='px-[2rem]'>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Item</span>
						<span className='panel_txt_header'>Details</span>
						<span className='panel_txt_header'>File</span>
					</div>

					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt'>Type of EIA Report Required</span>
						<Select
							style={{
								width: 'fit-content',
							}}
							onChange={(value) => handleChange('wrEiaReportType', value)}
							value={
								projectPreparationDetails?.wrEiaReportType ?? 'Choose option'
							}
							options={eiaData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							suffixIcon={selectIcon}
							className='select-dotted'
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>NEMA License available</span>
						<Select
							onChange={(value) =>
								handleChange('wrNemaLicenseAvailable', value)
							}
							value={projectPreparationDetails?.wrNemaLicenseAvailable}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrNemaLicenseAvailable?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<label
							className='cursor-pointer flex items-center'
							htmlFor='wrNemaLicenseFile'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='19'
								viewBox='0 0 15 19'
								fill='none'>
								<path
									d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
									fill='#0170BD'
								/>
							</svg>
							<input
								id='wrNemaLicenseFile'
								type='file'
								onChange={(e) => handleFile('wrNemaLicenseFile', e)}
								hidden
							/>
							<span className='table_view_txt underline ml-[.3rem]'>
								{projectPreparationDetails?.wrNemaLicenseFile
									? projectPreparationDetails?.wrNemaLicenseFile
											?.split('_')
											?.pop()
									: 'Attach file'}
							</span>
						</label>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>EIA Report Provided</span>
						<Select
							onChange={(value) => handleChange('wrEiaReportProvided', value)}
							value={projectPreparationDetails?.wrEiaReportProvided}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEiaReportProvided?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<label
							className='cursor-pointer flex items-center'
							htmlFor='wrEiaReportFile'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='19'
								viewBox='0 0 15 19'
								fill='none'>
								<path
									d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
									fill='#0170BD'
								/>
							</svg>
							<input
								id='wrEiaReportFile'
								type='file'
								onChange={(e) => handleFile('wrEiaReportFile', e)}
								hidden
							/>
							<span className='table_view_txt underline ml-[.3rem]'>
								{projectPreparationDetails?.wrEiaReportFile
									? projectPreparationDetails?.wrEiaReportFile
											?.split('_')
											?.pop()
									: 'Attach file'}
							</span>
						</label>
					</div>

					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt'>
							Are ALL stakeholders aware of project?
						</span>
						<Select
							style={{
								width: 'fit-content',
							}}
							onChange={(value) => handleChange('wrStakeholdersAware', value)}
							value={projectPreparationDetails?.wrStakeholdersAware}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrStakeholdersAware?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>
					</div>

					<Form.Item
						className='flex flex-col mt-[2rem]'
						label='Who may object to project?'>
						<TextArea
							value={value}
							onChange={(e) => setvalue(e.target.value)}
							onBlur={() => handleChange('wrProjectObjectors', value)}
							placeholder='List stakeholders who may object...'
							className='border-blue'
							rows={5}
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
}