import { Col, Collapse, InputNumber, Modal, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import RiksMitigationPanel from './step_three_panels/RiksMitigationPanel';
import DelegatedServicePanel from './step_three_panels/DelegatedServicePanel';
import BillingTransitionPanel from './step_three_panels/BillingTransitionPanel';
import BusinessPlanPanel from './step_three_panels/BusinessPlanPanel';
import { save } from '../../features/save/saveSlice';
import ViewFileButton from '../../components/ViewFileButton';
import { fetchSingleBid } from '../../features/obj/objSlice';

const expandIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M1.645 0L7 5.56275L12.355 0L14 1.71255L7 9L0 1.71255L1.645 0Z'
			fill='#0170BD'
		/>
	</svg>
);

const activeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M12.355 9L7 3.43725L1.645 9L0 7.28745L7 0L14 7.28745L12.355 9Z'
			fill='#0170BD'
		/>
	</svg>
);

const mainIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='8'
		height='13'
		viewBox='0 0 8 13'
		fill='none'>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 9.34766)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 9.34766)'
			fill='#0170BD'
		/>
	</svg>
);

export default function ProjectPreparationStepThreeView({ prev }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { projectObj, bidObj } = useSelector((state) => state.obj);
	const { saving } = useSelector((state) => state.save);

	const [activeKey, setactiveKey] = useState(['0']);
	const [formObj, setformObj] = useState(bidObj);

	const onChange = (key) => {
		setactiveKey(key);
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			onOk: handleSubmit,
			content: 'Are you sure you want to send this project to Deal Room?',
			okText: 'Submit',
			cancelText: 'Cancel',
		});
	};

	async function handleSubmit() {
		let saveObj = {
			projId: projectObj?.projId,
			projStatus: 'DEAL_SOURCING',
			url: '/usr/save_project.action',
		};

		await dispatch(save(saveObj));
		await navigate('/project-preparation/commited-projects');
	}

	async function handleFetch() {
		let obj = {
			projId: projectObj?.projId,
		};
		await dispatch(fetchSingleBid(obj));
	}

	useEffect(() => {}, [projectObj, formObj]);

	useEffect(() => {
		setformObj(bidObj);
	}, [bidObj]);

	useEffect(() => {
		handleFetch();
	}, []);

	const items = [
		{
			key: '0',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Business plan</span>
				</div>
			),
			children: <BusinessPlanPanel />,
		},
	];

	const items2 = [
		{
			key: '1',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Risk Mitigation Plan</span>
				</div>
			),
			children: <RiksMitigationPanel />,
		},
	];

	const items3 = [
		{
			key: '2',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
						Delegated Service Provision Agreement
					</span>
				</div>
			),
			children: <DelegatedServicePanel />,
		},
	];

	const items4 = [
		{
			key: '3',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
						Billing Transition Plan
					</span>
				</div>
			),
			children: <BillingTransitionPanel />,
		},
	];

	return (
		<>
			<div
				style={{
					maxWidth: '100%',
					width: '40.68756rem',
					height: '0.0625rem',
					opacity: 0.3,
					background: 'var(--Blue-grey, #7E93AE)',
				}}
				className='mt-[1.13rem]'></div>

			<div className='project_view_company_txt mt-[2.19rem]'>
				{projectObj?.accTradeName} | SSWP No: N/A
			</div>

			<div className='project_view_contact_txt mt-[1.5rem]'>
				Contact Person: {projectObj?.usrFirstName ?? 'N/A'}{' '}
				{projectObj?.usrLastName ?? ''} - {projectObj?.usrMobileNumber}
			</div>

			<div className='mb-[1.88rem] mt-[2.44rem]'>
				<Collapse
					className='collapse-white'
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items2}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items3}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items4}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='w-full flex flex-col items-center justify-center gap-y-[2.67rem] mt-[3.23rem] mb-[5rem]'>
				{projectObj?.projStatus === 'COMMITTED' ? (
					<>
						<button disabled={saving} onClick={confirm} className='cstm-btn-2'>
							{saving ? <Spin /> : 'Send to Deal Room'}
						</button>
						<div className='w-full flex items-center justify-center gap-x-[3.67rem]'>
							<button disabled={saving} onClick={() => prev()}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='62'
									height='24'
									viewBox='0 0 62 24'
									fill='none'>
									<path
										d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
										fill='#0873B9'
									/>
								</svg>
							</button>

							<span className='panel_num_txt'>3/3</span>
						</div>
					</>
				) : (
					<>
						<div
							style={{
								width: '100%',
								height: 'auto',
								border: '1px dotted #0873B9',
							}}
							className='bg-white p-[2rem] flex flex-col'>
							<span className='panel_header'>Term Sheet</span>
							<span className='fill_details_txt mt-[1.31rem]'>
								Please fill in the details below
							</span>

							<Row className='mt-[1rem]'>
								<Col span={24}>
									<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
										<span className='panel_txt_header'>Loan (KES)</span>
										<InputNumber
											readOnly
											parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
											formatter={(value) =>
												`${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											}
											value={formObj?.ltcLoanAmnt}
											className='input_borderless'
										/>
									</div>
								</Col>

								<Col span={24}>
									<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
										<span className='panel_txt_header'>Interest Rate (%)</span>
										<InputNumber
											readOnly
											value={formObj?.ltcLoanInterest}
											className='input_borderless'
										/>
									</div>
								</Col>

								<Col span={24}>
									<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
										<span className='panel_txt_header'>Loan Term (months)</span>
										<InputNumber
											readOnly
											value={formObj?.ltcLoanTerm}
											className='input_borderless'
										/>
									</div>
								</Col>

								<Col span={24}>
									<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
										<span className='panel_txt_header'>
											Term Sheet attachment
										</span>
										<ViewFileButton file={formObj?.ltcTermsheetFile} />
									</div>
								</Col>
							</Row>
						</div>

						<div className='w-full flex items-center justify-center gap-x-[11.67rem]'>
							<button disabled={saving} onClick={() => prev()}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='62'
									height='24'
									viewBox='0 0 62 24'
									fill='none'>
									<path
										d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
										fill='#0873B9'
									/>
								</svg>
							</button>

							<span className='panel_num_txt'>3/3</span>
						</div>
					</>
				)}
			</div>
		</>
	);
}
