import { Checkbox, Form, Radio, Select } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { adequacyData, reliabilityData } from '../../../utils';
import ViewFileButton from '../../../components/ViewFileButton';


const selectIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11'
		height='7'
		viewBox='0 0 11 7'
		fill='none'>
		<path
			d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
			fill='#0170BD'
		/>
	</svg>
);

export default function WaterSourcePanel() {
	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);

	function handleChecked(str) {
		let isChecked = Object.values(projectPreparationDetails ?? {}).includes(str);
		if (isChecked) return true;
		return false;
	}

	useEffect(() => {}, [projectObj, projectPreparationDetails]);

	return (
		<>
			<div className='flex flex-col px-[2rem] pb-[2rem]'>
				<div className='grid grid-cols-1 lg:grid-cols-2'>
					<Form.Item
						className='flex flex-col'
						label='Adequacy of water resource'>
						<Select
							disabled
							value={projectPreparationDetails?.wrWaterAdequacy}
							options={adequacyData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							suffixIcon={selectIcon}
							className='select-border-blue'
						/>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2'>
					<Form.Item className='flex flex-col' label='Seasonal reliability'>
						<Select
							disabled
							value={projectPreparationDetails?.wrWaterReliablity}
							options={reliabilityData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							suffixIcon={selectIcon}
							className='select-border-blue'
						/>
					</Form.Item>
				</div>

				<Form.Item className='flex flex-col' label='Water Quality'>
					<div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'>
						<Checkbox
							disabled
							value={'WQ sufficient for demand'}
							checked={handleChecked('WQ sufficient for demand')}
							className='paragraph_2'>
							WQ sufficient for demand
						</Checkbox>

						<Checkbox
							disabled
							value={'Treatment works provided'}
							checked={handleChecked('Treatment works provided')}
							className='paragraph_2'>
							Treatment works provided
						</Checkbox>

						<Checkbox
							disabled
							value={'WQ likely to affect demand'}
							checked={handleChecked('WQ likely to affect demand')}
							className='paragraph_2'>
							WQ likely to affect demand
						</Checkbox>
					</div>
				</Form.Item>

				<Form.Item
					className='flex flex-col'
					label='Statutory compliance - WATER'>
					<Radio.Group
						disabled
						className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'
						value={projectPreparationDetails?.wrWraCompliance}>
						<Radio value='Full compliance with WRA'>
							Full compliance with WRA
						</Radio>
						<Radio value='Compliance with WRA in process'>
							Compliance with WRA in process
						</Radio>
						<Radio value='Compliance with WRA outstanding'>
							Compliance with WRA outstanding
						</Radio>
					</Radio.Group>
				</Form.Item>

				<div className='flex items-center gap-x-[5.25rem]'>

				<ViewFileButton file={projectPreparationDetails?.wrWraAuthorizationPermitFile} />
				<ViewFileButton file={projectPreparationDetails?.wrNemaAuthorizationPermitFile} />

				</div>

				<Form.Item
					className='flex flex-col mt-[2rem]'
					label='Statutory compliance - NEMA'>
					<Radio.Group
						disabled
						className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'
						value={projectPreparationDetails?.wrNemaComliance}>
						<Radio value='Full compliance with NEMA'>
							Full compliance with NEMA
						</Radio>
						<Radio value='Compliance with NEMA in process'>
							Compliance with NEMA in process
						</Radio>
						<Radio value='Compliance with NEMA outstanding'>
							Compliance with NEMA outstanding
						</Radio>
					</Radio.Group>
				</Form.Item>

				<div className='flex items-center gap-x-[5.25rem]'>
				<ViewFileButton file={projectPreparationDetails?.wrNemaAuthorizationPermitFile} />
				<ViewFileButton file={projectPreparationDetails?.wrNemaLicenseFile} />
				</div>

				<Form.Item
					className='flex flex-col mt-[2rem]'
					label='Water resource risks'>
					<div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'>
						<Checkbox
							disabled
							value={'None'}
							checked={handleChecked('None')}
							className='paragraph_2'>
							None
						</Checkbox>

						<Checkbox
							disabled
							value={'Unexpected pollution from upstream'}
							checked={handleChecked('Unexpected pollution from upstream')}
							className='paragraph_2'>
							Unexpected pollution from upstream
						</Checkbox>

						<Checkbox
							disabled
							value={'Over abstraction upstream'}
							checked={handleChecked('Over abstraction upstream')}
							className='paragraph_2'>
							Over abstraction upstream
						</Checkbox>

						<Checkbox
							disabled
							value={'Drought'}
							checked={handleChecked('Drought')}
							className='paragraph_2'>
							Drought
						</Checkbox>
					</div>
				</Form.Item>

				<Form.Item
					className='flex flex-col mt-[2rem]'
					label='Hydrological Assessment Report Provided'>
					<Radio.Group
						disabled
						className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'
						value={projectPreparationDetails?.wrHydroAssessmentReport}>
						<Radio value='Provided'>Provided</Radio>
						<Radio value='Required but not provided'>
							Required but not provided
						</Radio>
						<Radio value='Not required'>Not required</Radio>
					</Radio.Group>
				</Form.Item>

				<div className='flex items-center gap-x-[5.25rem]'>
					<ViewFileButton
						file={projectPreparationDetails?.wrHydroAssessmentReportFile}
					/>
				</div>

				<Form.Item
					className='flex flex-col mt-[2rem]'
					label='Water Quality Analysis'>
					<Radio.Group
						disabled
						className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'
						value={projectPreparationDetails?.wrWaterQualityAnalysis}>
						<Radio value='Provided'>Provided</Radio>
						<Radio value='Required but not provided'>
							Required but not provided
						</Radio>
						<Radio value='Not required'>Not required</Radio>
					</Radio.Group>
				</Form.Item>

				<div className='flex items-center gap-x-[5.25rem]'>
					<ViewFileButton
						file={projectPreparationDetails?.wrWaterQualityAnalysisFile}
					/>
				</div>
			</div>
		</>
	);
}
