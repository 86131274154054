import { Checkbox, Form, Radio, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../../features/save/saveSlice';
import { uploadFile } from '../../../../features/obj/objSlice';
import { adequacyData, reliabilityData } from '../../../../utils';

const selectIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11'
		height='7'
		viewBox='0 0 11 7'
		fill='none'>
		<path
			d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
			fill='#0170BD'
		/>
	</svg>
);

export default function WaterSourcePanel({ handleFetch }) {
	const dispatch = useDispatch();

	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);

	const handleSave = async (data) => {
		let saveObj = {
			...data,
			wrId: projectPreparationDetails?.wrId,
			wrProjId: projectObj?.projId,
			url: '/usr/saveWaterResource.action',
		};
		await dispatch(save(saveObj));
	};

	async function handleChange(field, value) {
		let data = {
			[field]: value,
		};
		await handleSave(data);
		await handleFetch();
	}

	async function handleCheckedChange(field, value) {
		let isChecked = Object.values(projectPreparationDetails).includes(value);

		let data;

		if (isChecked) {
			data = {
				[field]: '',
			};
		} else {
			data = {
				[field]: value,
			};
		}
		await handleSave(data);
		await handleFetch();
	}

	const handleFile = async (field, e) => {
		let file = e.target.files[0];
		const formData = new FormData();

		formData.append('file', file);

		const res = await dispatch(uploadFile(formData));
		let data = {
			[field]: res?.payload,
		};

		await handleSave(data);
		await handleFetch();
	};

	function handleChecked(str) {
		let isChecked = Object.values(projectPreparationDetails).includes(str);
		if (isChecked) return true;
		return false;
	}

	useEffect(() => {}, [projectObj, projectPreparationDetails]);

	return (
		<>
			<div className='flex flex-col px-[2rem] pb-[2rem]'>
				<div className='grid grid-cols-1 lg:grid-cols-2'>
					<Form.Item
						className='flex flex-col'
						label='Adequacy of water resource'>
						<Select
							value={projectPreparationDetails?.wrWaterAdequacy}
							options={adequacyData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleChange('wrWaterAdequacy', value);
							}}
							suffixIcon={selectIcon}
							className='select-border-blue'
						/>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2'>
					<Form.Item className='flex flex-col' label='Seasonal reliability'>
						<Select
							value={projectPreparationDetails?.wrWaterReliablity}
							options={reliabilityData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleChange('wrWaterReliablity', value);
							}}
							suffixIcon={selectIcon}
							className='select-border-blue'
						/>
					</Form.Item>
				</div>

				<Form.Item className='flex flex-col' label='Water Quality'>
					<div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'>
						<Checkbox
							onChange={(e) =>
								handleCheckedChange('wrWaterQuality', e.target.value)
							}
							value={'WQ sufficient for demand'}
							checked={handleChecked('WQ sufficient for demand')}
							className='paragraph_2'>
							WQ sufficient for demand
						</Checkbox>

						<Checkbox
							// onChange={e => handleChange('wrWaterQuality', e.target.value)}
							value={'Treatment works provided'}
							checked={handleChecked('Treatment works provided')}
							className='paragraph_2'>
							Treatment works provided
						</Checkbox>

						<Checkbox
							// onChange={e => handleChange('wrWaterQuality', e.target.value)}
							value={'WQ likely to affect demand'}
							checked={handleChecked('WQ likely to affect demand')}
							className='paragraph_2'>
							WQ likely to affect demand
						</Checkbox>
					</div>
				</Form.Item>

				<Form.Item
					className='flex flex-col'
					label='Statutory compliance - WATER'>
					<Radio.Group
						className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'
						onChange={(e) => handleChange('wrWraCompliance', e.target.value)}
						value={projectPreparationDetails?.wrWraCompliance}>
						<Radio value='Full compliance with WRA'>
							Full compliance with WRA
						</Radio>
						<Radio value='Compliance with WRA in process'>
							Compliance with WRA in process
						</Radio>
						<Radio value='Compliance with WRA outstanding'>
							Compliance with WRA outstanding
						</Radio>
					</Radio.Group>
				</Form.Item>

				<div className='flex items-center gap-x-[5.25rem]'>
					<label className='cursor-pointer flex items-center' htmlFor='wrWraAuthorizationPermitFile'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='15'
							height='19'
							viewBox='0 0 15 19'
							fill='none'>
							<path
								d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
								fill='#0170BD'
							/>
						</svg>
						<input
							id='wrWraAuthorizationPermitFile'
							type='file'
							onChange={(e) => handleFile('wrWraAuthorizationPermitFile', e)}
							hidden
						/>
						<span className='attach_file_text ml-[.3rem]'>
							{projectPreparationDetails?.wrWraAuthorizationPermitFile
								? projectPreparationDetails?.wrWraAuthorizationPermitFile
										?.split('_')
										?.pop()
								: 'WRA Authorisation'}
						</span>
					</label>

					<label className='cursor-pointer flex items-center' htmlFor='wrNemaAuthorizationPermitFile'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='15'
							height='19'
							viewBox='0 0 15 19'
							fill='none'>
							<path
								d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
								fill='#0170BD'
							/>
						</svg>
						<input
							id='wrNemaAuthorizationPermitFile'
							type='file'
							onChange={(e) => handleFile('wrNemaAuthorizationPermitFile', e)}
							hidden
						/>
						<span className='attach_file_text ml-[.3rem]'>
							{projectPreparationDetails?.wrNemaAuthorizationPermitFile
								? projectPreparationDetails?.wrNemaAuthorizationPermitFile
										?.split('_')
										?.pop()
								: 'WRA Permit'}
						</span>
					</label>
				</div>

                <Form.Item
					className='flex flex-col mt-[2rem]'
					label='Statutory compliance - NEMA'>
					<Radio.Group
						className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'
						onChange={(e) => handleChange('wrNemaComliance', e.target.value)}
						value={projectPreparationDetails?.wrNemaComliance}>
						<Radio value='Full compliance with NEMA'>
							Full compliance with NEMA
						</Radio>
						<Radio value='Compliance with NEMA in process'>
							Compliance with NEMA in process
						</Radio>
						<Radio value='Compliance with NEMA outstanding'>
							Compliance with NEMA outstanding
						</Radio>
					</Radio.Group>
				</Form.Item>

				<div className='flex items-center gap-x-[5.25rem]'>
					<label className='cursor-pointer flex items-center' htmlFor='wrNemaAuthorizationPermitFile'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='15'
							height='19'
							viewBox='0 0 15 19'
							fill='none'>
							<path
								d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
								fill='#0170BD'
							/>
						</svg>
						<input
							id='wrNemaAuthorizationPermitFile'
							type='file'
							onChange={(e) => handleFile('wrNemaAuthorizationPermitFile', e)}
							hidden
						/>
						<span className='attach_file_text ml-[.3rem]'>
							{projectPreparationDetails?.wrNemaAuthorizationPermitFile
								? projectPreparationDetails?.wrNemaAuthorizationPermitFile
										?.split('_')
										?.pop()
								: 'NEMA Authorisation'}
						</span>
					</label>

					<label className='cursor-pointer flex items-center' htmlFor='wrNemaLicenseFile'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='15'
							height='19'
							viewBox='0 0 15 19'
							fill='none'>
							<path
								d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
								fill='#0170BD'
							/>
						</svg>
						<input
							id='wrNemaLicenseFile'
							type='file'
							onChange={(e) => handleFile('wrNemaLicenseFile', e)}
							hidden
						/>
						<span className='attach_file_text ml-[.3rem]'>
							{projectPreparationDetails?.wrNemaLicenseFile
								? projectPreparationDetails?.wrNemaLicenseFile
										?.split('_')
										?.pop()
								: 'NEMA Permit'}
						</span>
					</label>
				</div>

                <Form.Item className='flex flex-col mt-[2rem]' label='Water resource risks'>
					<div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'>
						<Checkbox
							onChange={(e) =>
								handleCheckedChange('wrWaterResourceRisk', e.target.value)
							}
							value={'None'}
							checked={handleChecked('None')}
							className='paragraph_2'>
							None
						</Checkbox>

						<Checkbox
							// onChange={e => handleChange('wrWaterQuality', e.target.value)}
							value={'Unexpected pollution from upstream'}
							checked={handleChecked('Unexpected pollution from upstream')}
							className='paragraph_2'>
							Unexpected pollution from upstream
						</Checkbox>

						<Checkbox
							// onChange={e => handleChange('wrWaterQuality', e.target.value)}
							value={'Over abstraction upstream'}
							checked={handleChecked('Over abstraction upstream')}
							className='paragraph_2'>
							Over abstraction upstream
						</Checkbox>

                        <Checkbox
							// onChange={e => handleChange('wrWaterQuality', e.target.value)}
							value={'Drought'}
							checked={handleChecked('Drought')}
							className='paragraph_2'>
							Drought
						</Checkbox>
					</div>
				</Form.Item>

                <Form.Item
					className='flex flex-col mt-[2rem]'
					label='Hydrological Assessment Report Provided'>
					<Radio.Group
						className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'
						onChange={(e) => handleChange('wrHydroAssessmentReport', e.target.value)}
						value={projectPreparationDetails?.wrHydroAssessmentReport}>
						<Radio value='Provided'>
							Provided
						</Radio>
						<Radio value='Required but not provided'>
							Required but not provided
						</Radio>
						<Radio value='Not required'>
							Not required
						</Radio>
					</Radio.Group>
				</Form.Item>

				<div className='flex items-center gap-x-[5.25rem]'>
					<label className='cursor-pointer flex items-center' htmlFor='wrHydroAssessmentReportFile'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='15'
							height='19'
							viewBox='0 0 15 19'
							fill='none'>
							<path
								d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
								fill='#0170BD'
							/>
						</svg>
						<input
							id='wrHydroAssessmentReportFile'
							type='file'
							onChange={(e) => handleFile('wrHydroAssessmentReportFile', e)}
							hidden
						/>
						<span className='attach_file_text ml-[.3rem]'>
							{projectPreparationDetails?.wrHydroAssessmentReportFile
								? projectPreparationDetails?.wrHydroAssessmentReportFile
										?.split('_')
										?.pop()
								: 'Hydrological Assessment Report'}
						</span>
					</label>

				</div>

                <Form.Item
					className='flex flex-col mt-[2rem]'
					label='Water Quality Analysis'>
					<Radio.Group
						className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1rem]'
						onChange={(e) => handleChange('wrWaterQualityAnalysis', e.target.value)}
						value={projectPreparationDetails?.wrWaterQualityAnalysis}>
						<Radio value='Provided'>
							Provided
						</Radio>
						<Radio value='Required but not provided'>
							Required but not provided
						</Radio>
						<Radio value='Not required'>
							Not required
						</Radio>
					</Radio.Group>
				</Form.Item>

				<div className='flex items-center gap-x-[5.25rem]'>
					<label className='cursor-pointer flex items-center' htmlFor='wrWaterQualityAnalysisFile'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='15'
							height='19'
							viewBox='0 0 15 19'
							fill='none'>
							<path
								d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
								fill='#0170BD'
							/>
						</svg>
						<input
							id='wrWaterQualityAnalysisFile'
							type='file'
							onChange={(e) => handleFile('wrWaterQualityAnalysisFile', e)}
							hidden
						/>
						<span className='attach_file_text ml-[.3rem]'>
							{projectPreparationDetails?.wrWaterQualityAnalysisFile
								? projectPreparationDetails?.wrWaterQualityAnalysisFile
										?.split('_')
										?.pop()
								: 'Water Quality Analysis'}
						</span>
					</label>

				</div>

			</div>
		</>
	);
}
