import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function TarrifFlatRateDetails() {
	const { projectObj } = useSelector((state) => state.obj);

	const {
		projIndividualRate,
		projKioskRate,
		projInstitution,
		projCommercial,
	} = projectObj;

	useEffect(() => {}, [projectObj]);

	return (
		<>
			<Row>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt_header'>Item</span>
						<span className='px-[2rem] panel_txt_header'>Details</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Individual</span>
						<span className='px-[2rem] panel_txt'>
							{projIndividualRate ?? "N/A"}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Kiosk</span>
						<span className='px-[2rem] panel_txt'>
							{projKioskRate ?? "N/A"}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Institution(school, HCF)
						</span>
						<span className='px-[2rem] panel_txt'>{projInstitution ?? "N/A"}</span>
					</div>

					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Commerical</span>
						<span className='px-[2rem] panel_txt'>{projCommercial ?? "N/A"}</span>
					</div>
				</Col>
			</Row>
		</>
	);
}
