import { useEffect, useRef, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import BreadCrumb from '../../layout/BreadCrumb';
import { useSelector } from 'react-redux';
import { Steps } from 'antd';
import GovernanceStep from './project_details_steps/GovernanceStep';
import WaterSourceStep from './project_details_steps/WaterSourceStep';
import ApplicantParticularsStep from './project_details_steps/ApplicantParticularsStep';
import FinancialPositionStep from './project_details_steps/FinancialPositionStep';
import TypeOfFinancingStep from './project_details_steps/TypeOfFinancingStep';
import FinalVerdictStep from './project_details_steps/FinalVerdictStep';

export default function ProjectDetailView() {
	const { projectObj } = useSelector((state) => state.obj);

	const [current, setCurrent] = useState(0);

	const view = useRef();

	const next = () => {
		setCurrent(current + 1);
		view?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const prev = () => {
		setCurrent(current - 1);
		view?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const onChange = async (value) => {
		await setCurrent(value);
		await view?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	useEffect(() => {}, [projectObj]);

	const breadList = [
		{
			title: 'Home',
			href: '/',
		},
		{
			title: 'Screening',
			href: '/',
		},
		{
			title: projectObj?.accTradeName?.toUpperCase(),
		},
	];

	const steps = [
		{
			key: 0,
			title: 'Governance',
			content: <GovernanceStep next={next} />,
		},
		{
			key: 1,
			title: (
				<span>
					Water source <br /> & infrastructure
				</span>
			),
			content: <WaterSourceStep next={next} prev={prev} />,
		},
		{
			key: 2,
			title: (
				<span>
					Applicant <br /> particulars
				</span>
			),
			content: <ApplicantParticularsStep next={next} prev={prev} />,
		},
		{
			key: 3,
			title: (
				<span>
					Summary of <br /> financial position
				</span>
			),
			content: <FinancialPositionStep next={next} prev={prev} />,
		},
		{
			key: 4,
			title: (
				<span>
					Type of <br /> financing
				</span>
			),
			content: <TypeOfFinancingStep next={next} prev={prev} />,
		},
		{
			key: 5,
			title: 'Final verdict',
			content: <FinalVerdictStep prev={prev} />,
		},
	];

	const items = steps.map((item) => ({
		key: item.key,
		title: item.title,
	}));

	return (
		<div ref={view}>
			<PageHeader header={'Screening'} />
			<div className='mt-[.94rem]'>
				<BreadCrumb breadList={breadList} />
			</div>

			<div className='mt-[4.06rem]'>
				<Steps
					onChange={onChange}
					current={current}
					items={items}
					className='project-detail'
				/>
			</div>

			<div className='mt-[2.65rem]'>{steps[current].content}</div>
		</div>
	);
}
