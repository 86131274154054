import { Col, Row, Select } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { booleanData } from '../../../utils';
import ViewFileButton from '../../../components/ViewFileButton';

const selectIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11'
		height='7'
		viewBox='0 0 11 7'
		fill='none'>
		<path
			d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
			fill='#222222'
		/>
	</svg>
);

export default function EngineeringPanel() {
	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);

	useEffect(() => {}, [projectObj, projectPreparationDetails]);

	return (
		<>
			<Row className='px-[2rem]'>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Item</span>
						<span className='panel_txt_header'>Details</span>
						<span className='panel_txt_header'>File</span>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>Map of supply area</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrEngMap}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEngMap?.toUpperCase() === 'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton file={projectPreparationDetails?.wrEngMapFile} />
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>Engineering design report</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrEngDesignReport}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEngDesignReport?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton
							file={projectPreparationDetails?.wrEngDesignReportFile}
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>Drawings</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrEngDrawing}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEngDrawing?.toUpperCase() === 'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton
							file={projectPreparationDetails?.wrEngDrawingFile}
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>Specifications</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrEngSpecification}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEngSpecification?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton
							file={projectPreparationDetails?.wrEngSpecificationFile}
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>Costed Bills of Quantities</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrEngBillOfQuantity}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEngBillOfQuantity?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton
							file={projectPreparationDetails?.wrEngBillOfQuantityFile}
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>Construction Plan</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrEngConstructionPlan}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEngConstructionPlan?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton
							file={projectPreparationDetails?.wrEngConstructionPlanFile}
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>Tender materials</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrEngTenderMaterial}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEngTenderMaterial?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton
							file={projectPreparationDetails?.wrEngTenderMaterialFile}
						/>
					</div>
				</Col>
			</Row>
		</>
	);
}
