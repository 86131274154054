import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createData, formatMoney } from '../../../../utils';
import GradingComponent from '../../../../components/GradingComponent';

export default function LoanInformationDetails({ handleFetch }) {
	const { projectObj } = useSelector((state) => state.obj);
	const { projectScoreStepFive } = useSelector((state) => state.fetch);

	const {
		projDescSubProj,
		projProbSolved,
		projEstCost,
		projCost,
		projMoneySource,
		projOtherPartyFinance,
		projInformGov,
		projPurpose,
	} = projectObj;

	useEffect(() => {}, [projectObj, projectScoreStepFive]);

	const arr1 = [
		createData(
			projOtherPartyFinance,
			'Source of 3rd party financing',
			'projOtherPartyFinance',
			10
		),
	];

	return (
		<>
			<Row>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt_header'>Item</span>
						<span className='px-[2rem] panel_txt_header'>Details</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Description of sub-project for which the loan is applied
						</span>
						<span className='px-[2rem] panel_txt'>{projDescSubProj}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Problem being solved</span>
						<span className='px-[2rem] panel_txt'>{projProbSolved}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Total estimated project cost
						</span>
						<span className='px-[2rem] panel_txt'>
							{formatMoney(projEstCost)}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Amount of loan required</span>
						<span className='px-[2rem] panel_txt'>
							{formatMoney(projCost)}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Purpose of the Project</span>
						<span className='px-[2rem] panel_txt'>{projPurpose}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							How funds will be generated to repay the loan
						</span>
						<span className='px-[2rem] panel_txt'>{projMoneySource}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Has county government been informed
						</span>
						<span className='px-[2rem] panel_txt'>{projInformGov}</span>
					</div>
				</Col>

				<Col className='mt-[1rem]' span={24}>
					{arr1?.map((item) => {
						return (
							<GradingComponent
								handleFetch={handleFetch}
								arrayList={projectScoreStepFive}
								data={item.data}
								itemKey={item.itemKey}
								itemName={item.itemName}
								isFile={item.isFile}
								overalScore={item.overalScore}
							/>
						);
					})}
				</Col>
			</Row>
		</>
	);
}
