import { Col, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../../features/save/saveSlice';
import {
	fetchBusinessPlan,
	uploadFile,
} from '../../../../features/obj/objSlice';
import { booleanData } from '../../../../utils';

const selectIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11'
		height='7'
		viewBox='0 0 11 7'
		fill='none'>
		<path
			d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
			fill='#222222'
		/>
	</svg>
);

export default function BusinessPlanPanel() {
	const dispatch = useDispatch();

	const { projectObj, businessPlan } = useSelector((state) => state.obj);

	const [formObj, setformObj] = useState(businessPlan);

	const handleSave = async (data) => {
		let saveObj = {
			...data,
			bspId: businessPlan?.bspId,
			bspProjId: projectObj?.projId,
			url: '/usr/saveBusinessPlan.action',
		};
		await dispatch(save(saveObj));
	};

	async function handleChange(field, value) {
		setformObj((prev) => ({
			...prev,
			[field]: value,
		}));
	}

	async function handleSelectChange(field, value) {
		let data = {
			[field]: value,
		};
		await handleSave(data);
		await handleFetch();
	}

	const handleFile = async (field, e) => {
		let file = e.target.files[0];
		const formData = new FormData();

		formData.append('file', file);

		const res = await dispatch(uploadFile(formData));
		let data = {
			[field]: res?.payload,
		};

		await handleSave(data);
		await handleFetch();
	};

	async function handleBlur() {
		await handleSave(formObj);
		await handleFetch();
	}

	async function handleFetch() {
		await dispatch(fetchBusinessPlan(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj]);

	useEffect(() => {
		handleFetch();
	}, []);

	useEffect(() => {}, [projectObj, formObj]);

	useEffect(() => {
		setformObj(businessPlan);
	}, [businessPlan]);

	return (
		<>
			<Row className='px-[2rem]'>
				<Col span={24}>
					<span className='heading_6'>Term Sheet</span>
				</Col>
				<Col span={24} className='mt-[1.81rem]'>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Item</span>
						<span className='panel_txt_header col-span-2'>Value</span>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Loan (KES)</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspLoan', e.target.value)}
							value={formObj?.bspLoan}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Interest Rate (%)</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspRateAve', e.target.value)}
							value={formObj?.bspRateAve}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Loan Term (months) (%)</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspLoanTerm', e.target.value)}
							value={formObj?.bspLoanTerm}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Attach Business Plan</span>
						<label
							className='cursor-pointer flex items-center'
							htmlFor='bspPlanFile'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='19'
								viewBox='0 0 15 19'
								fill='none'>
								<path
									d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
									fill='#0170BD'
								/>
							</svg>
							<input
								id='bspPlanFile'
								type='file'
								onChange={(e) => handleFile('bspPlanFile', e)}
								hidden
							/>
							<span className='table_view_txt underline ml-[.3rem]'>
								{formObj?.bspPlanFile
									? formObj?.bspPlanFile?.split('_')?.pop()
									: 'Attach file'}
							</span>
						</label>
					</div>
				</Col>
			</Row>

			<Row className='px-[2rem] mt-[2.62rem]'>
				<Col span={24}>
					<span className='heading_6'>Scenarios evaluated</span>
				</Col>
				<Col span={24} className='mt-[1.81rem]'>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>No</span>
						<span className='panel_txt_header'>1</span>
						<span className='panel_txt_header'>2</span>
						<span className='panel_txt_header'>3</span>
						<span className='panel_txt_header'>4</span>
						<span className='panel_txt_header col-span-2'>5</span>
					</div>
				</Col>
				<Col span={24} className='mt-[]'>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>
							Scenario Description
						</span>
						<span className='panel_txt_header'>
							2nd portion of <br />
							Subsidy = 0
						</span>
						<span className='panel_txt_header'>
							Low <br />
							revenue
						</span>
						<span className='panel_txt_header'>
							High <br />
							interest rate
						</span>
						<span className='panel_txt_header'>
							Moderate <br />
							interest rate
						</span>
						<span className='panel_txt_header col-span-2'>
							High <br />
							Inflation rate
						</span>
					</div>
				</Col>

				<Col span={24} className='mt-[]'>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>Item</span>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>Loan Sum (KES)</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnLoanSumOne', e.target.value)}
							value={formObj?.bspScnLoanSumOne}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnLoanSumTwo', e.target.value)}
							value={formObj?.bspScnLoanSumTwo}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnLoanSumThree', e.target.value)
							}
							value={formObj?.bspScnLoanSumThree}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnLoanSumFour', e.target.value)
							}
							value={formObj?.bspScnLoanSumFour}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnLoanSumFive', e.target.value)
							}
							value={formObj?.bspScnLoanSumFive}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>
							Interest Rate (%)
						</span>
						<Input placeholder="0"
							// style={{
							// 	borderRight: '1px solid rgba(207, 240, 252, 0.40)',
							// }}
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInterestRateOne', e.target.value)
							}
							value={formObj?.bspInterestRateOne}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInterestRateTwo', e.target.value)
							}
							value={formObj?.bspInterestRateTwo}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInterestRateThree', e.target.value)
							}
							value={formObj?.bspInterestRateThree}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInterestRateFour', e.target.value)
							}
							value={formObj?.bspInterestRateFour}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInterestRateFive', e.target.value)
							}
							value={formObj?.bspInterestRateFive}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>
							Inflation Rate (%)
						</span>
						<Input placeholder="0"
							// style={{bspInflation
							// 	borderRight: '1px solid rgba(207, 240, 252, 0.40)',
							// }}
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInflationRateOne', e.target.value)
							}
							value={formObj?.bspInflationRateOne}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInflationRateTwo', e.target.value)
							}
							value={formObj?.bspInflationRateTwo}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInflationRateThree', e.target.value)
							}
							value={formObj?.bspInflationRateThree}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInflationRateFour', e.target.value)
							}
							value={formObj?.bspInflationRateFour}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspInflationRateFive', e.target.value)
							}
							value={formObj?.bspInflationRateFive}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>
							Loan Term (months)
						</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTermSumOne', e.target.value)}
							value={formObj?.bspScnTermSumOne}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTermSumTwo', e.target.value)}
							value={formObj?.bspScnTermSumTwo}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnTermSumThree', e.target.value)
							}
							value={formObj?.bspScnTermSumThree}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnTermSumFour', e.target.value)
							}
							value={formObj?.bspScnTermSumFour}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnTermSumFive', e.target.value)
							}
							value={formObj?.bspScnTermSumFive}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>
							Monthly Loan Repayment with 15% collateral savings
						</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnMnthRepayOne', e.target.value)
							}
							value={formObj?.bspScnMnthRepayOne}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnMnthRepayTwo', e.target.value)
							}
							value={formObj?.bspScnMnthRepayTwo}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnMnthRepayThree', e.target.value)
							}
							value={formObj?.bspScnMnthRepayThree}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnMnthRepayFour', e.target.value)
							}
							value={formObj?.bspScnMnthRepayFour}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnMnthRepayFive', e.target.value)
							}
							value={formObj?.bspScnMnthRepayFive}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>Baseline IC</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnBaselineOne', e.target.value)
							}
							value={formObj?.bspScnBaselineOne}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnBaselineTwo', e.target.value)
							}
							value={formObj?.bspScnBaselineTwo}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnBaselineThree', e.target.value)
							}
							value={formObj?.bspScnBaselineThree}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnBaselineFour', e.target.value)
							}
							value={formObj?.bspScnBaselineFour}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnBaselineFive', e.target.value)
							}
							value={formObj?.bspScnBaselineFive}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>Target IC</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTargetOne', e.target.value)}
							value={formObj?.bspScnTargetOne}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTargetTwo', e.target.value)}
							value={formObj?.bspScnTargetTwo}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnTargetThree', e.target.value)
							}
							value={formObj?.bspScnTargetThree}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTargetFour', e.target.value)}
							value={formObj?.bspScnTargetFour}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTargetFive', e.target.value)}
							value={formObj?.bspScnTargetFive}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>
							Average Tariff (Ksh/m3)
						</span>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTariffOne', e.target.value)}
							value={formObj?.bspScnTariffOne}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTariffTwo', e.target.value)}
							value={formObj?.bspScnTariffTwo}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) =>
								handleChange('bspScnTariffThree', e.target.value)
							}
							value={formObj?.bspScnTariffThree}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTariffFour', e.target.value)}
							value={formObj?.bspScnTariffFour}
							className='input_borderless'
						/>
						<Input placeholder="0"
							onBlur={handleBlur}
							onChange={(e) => handleChange('bspScnTariffFive', e.target.value)}
							value={formObj?.bspScnTariffFive}
							className='input_borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>
							Is there a cash flow shortage?
						</span>

						<Select
							value={businessPlan?.bspScnCashShortOne ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnCashShortOne', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>

						<Select
							value={businessPlan?.bspScnCashShortTwo ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnCashShortTwo', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>

						<Select
							value={businessPlan?.bspScnCashShortThree ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnCashShortThree', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>

						<Select
							value={businessPlan?.bspScnCashShortFour ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnCashShortFour', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>

						<Select
							value={businessPlan?.bspScnCashShortFive ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnCashShortFive', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>
					</div>
				</Col>

				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
						<span className='panel_txt_header col-span-2'>
							Is tariff affordable?
						</span>
						<Select
							value={businessPlan?.bspScnAffordOne ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnAffordOne', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>

						<Select
							value={businessPlan?.bspScnAffordTwo ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnAffordTwo', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>

						<Select
							value={businessPlan?.bspScnAffordThree ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnAffordThree', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>

						<Select
							value={businessPlan?.bspScnAffordFour ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnAffordFour', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>

						<Select
							value={businessPlan?.bspScnAffordFive ?? 'NO'}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							onChange={(value) => {
								handleSelectChange('bspScnAffordFive', value);
							}}
							suffixIcon={selectIcon}
							className='select-borderless'
						/>
					</div>
				</Col>
			</Row>
		</>
	);
}
