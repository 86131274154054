import { Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatMoney, previewPaper } from '../../../../utils';
import { fetchBudgetItems } from '../../../../features/fetch/fetchSlice';

export default function BudgetItemsDetails() {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);

	const { loading, budgetItems } = useSelector((state) => state.fetch);

	async function handleFetch() {
		await dispatch(fetchBudgetItems(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj, budgetItems]);

	useEffect(() => {
		handleFetch();
	}, []);

	const columns = [
		{
			title: 'Item',
			dataIndex: 'biItem',
		},
		{
			title: 'Budget',
			dataIndex: 'biBudget',
            render: item => <span>{formatMoney(item)}</span>
		},
		{
			title: 'Comment',
			dataIndex: 'biComment',
		},
	];

	return (
		<>
			<div className='w-full mt-[2.75rem]'>
				<Table
					loading={loading}
					pagination={{
						defaultPageSize: 7,
						hideOnSinglePage: true,
						pageSizeOptions: [7, 15, 50, 100],
					}}
					columns={columns}
					dataSource={budgetItems}
				/>
			</div>
		</>
	);
}
