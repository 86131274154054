import { Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOperationStaff } from '../../../../features/fetch/fetchSlice';
import { formatMoney } from '../../../../utils';

export default function CapitalSourcesDetails() {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);

	async function handleFetch() {
		await dispatch(fetchOperationStaff(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj]);

	useEffect(() => {
		handleFetch();
	}, []);

	const {
		projLoanSum,
		projLoanCalculated,
		projSswpSum,
		projSswpCalculated,
		proj3rdpartySum,
		proj3rdpartyCalculated,
		projTotalSum,
		projTotalCalculated,
	} = projectObj;

	const columns = [
		{
			title: 'Source',
			dataIndex: 'value1',
		},
		{
			title: 'Amount',
			dataIndex: 'value2',
            render: item => <span>{formatMoney(item)}</span>
		},
		{
			title: 'Percentage(%)',
			dataIndex: 'value3',
		},
	];

	const data = [
		{
			value1: 'Loans',
			value2: projLoanSum,
			value3: projLoanCalculated,
		},
		{
			value1: 'SSP',
			value2: projSswpSum,
			value3: projSswpCalculated,
		},
		{
			value1: '3rd Party',
			value2: proj3rdpartySum,
			value3: proj3rdpartyCalculated,
		},
		{
			value1: 'Total',
			value2: projTotalSum,
            value3: projTotalCalculated,
		},
	];

	return (
		<>
			<div className='w-full mt-[2.75rem]'>
				<Table
					pagination={{
						defaultPageSize: 7,
						hideOnSinglePage: true,
						pageSizeOptions: [7, 15, 50, 100],
					}}
					columns={columns}
					dataSource={data}
				/>
			</div>
		</>
	);
}
