import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../../components/PageHeader';
import BreadCrumb from '../../../layout/BreadCrumb';
import ProjectPreparationStepOne from './ProjectPreparationStepOne';
import { fetchProjectPreparationDetails } from '../../../features/obj/objSlice';
import ProjectPreparationStepTwo from './ProjectPreparationStepTwo';
import ProjectPreparationStepThree from './ProjectPreparationStepThree';

export default function PrepareProjectMain() {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);

	const [current, setCurrent] = useState(0);

	const view = useRef();

	const next = () => {
		setCurrent(current + 1);
		view?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const prev = () => {
		setCurrent(current - 1);
		view?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	async function handleFetch() {
		await dispatch(fetchProjectPreparationDetails(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj]);

	useEffect(() => {
		handleFetch();
	}, []);

	const breadList = [
		{
			title: 'Project Preparation',
			href: '/project-preparation/commited-projects',
		},
		{
			title: 'Committed Projects ',
			href: '/project-preparation/commited-projects',
		},
		{
			title: projectObj?.accTradeName?.toUpperCase(),
		},
	];

	const steps = [
		{
			key: 0,
			title: 'Step 1',
			content: <ProjectPreparationStepOne handleFetch={handleFetch} next={next} />,
		},
		{
			key: 1,
			title: 'Step 2',
			content: <ProjectPreparationStepTwo handleFetch={handleFetch} next={next} prev={prev} />,
		},
		{
			key: 2,
			title: 'Step 3',
			content: <ProjectPreparationStepThree handleFetch={handleFetch} prev={prev} />,
		},
	];

	return (
		<div ref={view}>
			<PageHeader header={'Project preparation'} />
			<div className='mt-[.94rem]'>
				<BreadCrumb breadList={breadList} />
			</div>

			<div className='mt-[2.65rem]'>{steps[current].content}</div>
		</div>
	);
}
