import { Col, Row } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createData, formatMoney } from '../../../../utils';
import GradingComponent from '../../../../components/GradingComponent';

export default function FinancialSummaryDetails({ handleFetch }) {
	const { projectObj } = useSelector((state) => state.obj);
	const { projectScoreStepFour } = useSelector((state) => state.fetch);

	const {
		projFinDate,
		projHandCash,
		projBankCash,
		projTotalDebtors,
		projLiquidAssets,
		projLiabilities,
		projNetFinances,
		projBillingSystem,
	} = projectObj;

	useEffect(() => {}, [projectObj, projectScoreStepFour]);

	const arr1 = [
		createData(
			projBillingSystem,
			'Type of billing system',
			'projBillingSystem',
			5
		),
	];

	return (
		<>
			<Row>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt_header'>Item</span>
						<span className='px-[2rem] panel_txt_header'>Details</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>As on</span>
						<span className='px-[2rem] panel_txt'>
							{moment(projFinDate).format('LL')}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Cash in hand (petty cash)
						</span>
						<span className='px-[2rem] panel_txt'>{projHandCash}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Cash in bank account(s)</span>
						<span className='px-[2rem] panel_txt'>{projBankCash}</span>
					</div>

					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Total debtors (Money owed to project)
						</span>
						<span className='px-[2rem] panel_txt'>
							{formatMoney(projTotalDebtors)}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Total liquid assets [(a) + (b) + (c)]
						</span>
						<span className='px-[2rem] panel_txt'>
							{formatMoney(projLiquidAssets)}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Liabilities(Money owed by project to others)
						</span>
						<span className='px-[2rem] panel_txt'>
							{formatMoney(projLiabilities)}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Net financial position [(d) - (e)]
						</span>
						<span className='px-[2rem] panel_txt'>
							{formatMoney(projNetFinances)}
						</span>
					</div>

				</Col>

				<Col className='mt-[1rem]' span={24}>
					{arr1?.map((item) => {
						return (
							<GradingComponent
								handleFetch={handleFetch}
								arrayList={projectScoreStepFour}
								data={item.data}
								itemKey={item.itemKey}
								itemName={item.itemName}
								isFile={item.isFile}
								overalScore={item.overalScore}
							/>
						);
					})}
				</Col>
			</Row>
		</>
	);
}
