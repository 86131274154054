import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  newProjectObj: {},
  projectObj: {},
  projectPreparationDetails: {},
  businessPlan: {},
  bidObj: {},
};

export const fetchProjectPreparationDetails = createAsyncThunk(
  "objSlice/fetchProjectPreparationDetails",
  async (id) => {
    const res = await axios
      .get(`${url}/usr/fetchWaterResource.action?wrProjId=${id}`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const uploadFile = createAsyncThunk(
  "objSlice/uploadFile",
  async (formData) => {
    const res = await axios
      .post(`${url}/usr/postImages.action`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data.jsonData);
    return res;
  }
);

export const fetchBusinessPlan = createAsyncThunk(
  "fetchSlice/fetchBusinessPlan",
  async (id) => {
    const res = await axios
      .get(`${url}/usr/fetchBusinessPlan.action?bspProjId=${id}`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const fetchSingleBid = createAsyncThunk(
  "fetchSlice/fetchSingleBid",
  async (obj) => {
    const res = await axios
      .get(`${url}/usr/fetchLoanTermConditions.action?ltcProjId=${obj?.projId}`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const objSlice = createSlice({
  name: "obj",
  initialState,
  reducers: {
    setNewProjectObj: (state, action) => {
      state.newProjectObj = action.payload;
    },
    setProjectObj: (state, action) => {
      state.projectObj = action.payload;
    },
    clearProjectObj: (state) => {
      state.projectObj = {};
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchSingleBid.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleBid.fulfilled, (state, action) => {
        state.loading = false;
        state.bidObj = action.payload ? action.payload[0] : {};
      })
      .addCase(fetchSingleBid.rejected, (state) => {
        state.loading = false;
      })

      .addCase(fetchBusinessPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBusinessPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.businessPlan = action.payload ? action.payload[0] : {};
      })
      .addCase(fetchBusinessPlan.rejected, (state) => {
        state.loading = false;
      })

      .addCase(fetchProjectPreparationDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjectPreparationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.projectPreparationDetails = action.payload
          ? action.payload[0]
          : {};
      })
      .addCase(fetchProjectPreparationDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default objSlice.reducer;
export const { setNewProjectObj, setProjectObj, clearProjectObj } =
  objSlice.actions;
