import { Col, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function WaterSourcePanel() {
	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);

	const [formObj, setformObj] = useState(projectPreparationDetails);

	useEffect(() => {}, [projectObj, formObj]);

	useEffect(() => {
		setformObj(projectPreparationDetails);
	}, [projectPreparationDetails]);

	return (
		<>
			<Row className='px-[2rem]'>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-4'>
						<span className='panel_txt_header'>ITEM</span>
						<span className='panel_txt_header'>UNIT</span>
						<span className='panel_txt_header'>EXISTING</span>
						<span className='panel_txt_header'>FUTURE</span>
					</div>

					<div className='panel_card w-full grid grid-cols-4'>
						<span className='panel_txt'>ICs</span>
						<span className='panel_txt'>m3/day</span>
						<Input
							readOnly
							value={formObj?.wrIcsExisting}
							className='input_borderless'
						/>
						<Input
							readOnly
							value={formObj?.wrIcsFuture}
							className='input_borderless'
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-4'>
						<span className='panel_txt'>Kiosks</span>
						<span className='panel_txt'>m3/day</span>
						<Input
							readOnly
							value={formObj?.wrKiosksExisting}
							className='input_borderless'
						/>
						<Input
							readOnly
							value={formObj?.wrKiosksFuture}
							className='input_borderless'
						/>
					</div>
				</Col>
			</Row>
		</>
	);
}
