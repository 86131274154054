import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createData } from '../../../../utils';
import GradingComponent from '../../../../components/GradingComponent';

export default function FinancialAttachmentDetails({handleFetch}) {
	const { projectObj } = useSelector((state) => state.obj);
	const { projectScoreStepFour } = useSelector((state) => state.fetch);

	const {
		projIncomeExpenditureFile,
		projAudiAccFile,
		projAudiAccFile2,
		projAudiAccFile3,
	} = projectObj;

	useEffect(() => {}, [projectObj, projectScoreStepFour]);

	const arr1 = [
		createData(
			projIncomeExpenditureFile,
			'Most recent months financial data',
			'projIncomeExpenditureFile',
			10,
			true
		),
		createData(
			projAudiAccFile,
			'First Audit Report',
			'projAudiAccFile',
			1,
			true
		),
		createData(
			projAudiAccFile2,
			'Second audit report',
			'projAudiAccFile2',
			1,
			true
		),
		createData(
			projAudiAccFile3,
			'Third Audit Report',
			'projAudiAccFile3',
			1,
			true
		),
	];

	return (
		<>
			<Row>
			<Col span={24}>
					{arr1?.map((item) => {
						return (
							<GradingComponent
								handleFetch={handleFetch}
								arrayList={projectScoreStepFour}
								data={item.data}
								itemKey={item.itemKey}
								itemName={item.itemName}
								isFile={item.isFile}
								overalScore={item.overalScore}
							/>
						);
					})}
				</Col>
			</Row>
		</>
	);
}
