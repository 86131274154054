import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function AuditorContactDetails() {
	const { projectObj } = useSelector((state) => state.obj);

	const {
		projAuditorName ,
		projAuditorFirm ,
		projAuditorTelephone ,
		projAuditorEmail ,
	} = projectObj;

	useEffect(() => {}, [projectObj]);

	return (
		<>
			<Row>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt_header'>Item</span>
						<span className='px-[2rem] panel_txt_header'>Details</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Name</span>
						<span className='px-[2rem] panel_txt'>
							{projAuditorName ?? "N/A"}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Firm</span>
						<span className='px-[2rem] panel_txt'>
							{projAuditorFirm ?? "N/A"}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Telephone
						</span>
						<span className='px-[2rem] panel_txt'>{projAuditorTelephone ?? "N/A"}</span>
					</div>

					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Email</span>
						<span className='px-[2rem] panel_txt'>{projAuditorEmail ?? "N/A"}</span>
					</div>
				</Col>
			</Row>
		</>
	);
}
