import { Collapse, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import RiksMitigationPanel from './step_three_panels/RiksMitigationPanel';
import DelegatedServicePanel from './step_three_panels/DelegatedServicePanel';
import BillingTransitionPanel from './step_three_panels/BillingTransitionPanel';
import BusinessPlanPanel from './step_three_panels/BusinessPlanPanel';
import { save } from '../../../features/save/saveSlice';

const expandIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M1.645 0L7 5.56275L12.355 0L14 1.71255L7 9L0 1.71255L1.645 0Z'
			fill='#0170BD'
		/>
	</svg>
);

const activeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M12.355 9L7 3.43725L1.645 9L0 7.28745L7 0L14 7.28745L12.355 9Z'
			fill='#0170BD'
		/>
	</svg>
);

const mainIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='8'
		height='13'
		viewBox='0 0 8 13'
		fill='none'>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 9.34766)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 9.34766)'
			fill='#0170BD'
		/>
	</svg>
);

export default function ProjectPreparationStepThree({ prev, handleFetch }) {
    const dispatch = useDispatch();
	const navigate = useNavigate();

	const { projectObj } = useSelector((state) => state.obj);
    const { saving } = useSelector((state) => state.save);

	const [activeKey, setactiveKey] = useState(['0']);

	const onChange = (key) => {
		setactiveKey(key);
	};

    const confirm = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			onOk: handleSubmit,
			content: 'Are you sure you want to send this project to Deal Room?',
			okText: 'Submit',
			cancelText: 'Cancel',
		});
	};

    async function handleSubmit() {
		let saveObj = {
			projId: projectObj?.projId,
			projStatus: 'DEAL_SOURCING',
			url: '/usr/save_project.action',
		};

		await dispatch(save(saveObj));
		await navigate('/project-preparation/commited-projects');
	}

	useEffect(() => {}, [projectObj]);

	const items = [
		{
			key: '0',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
                    Business plan
					</span>
				</div>
			),
			children: <BusinessPlanPanel handleFetch={handleFetch} />,
		},
	];

	const items2 = [
		{
			key: '1',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Risk Mitigation Plan</span>
				</div>
			),
			children: <RiksMitigationPanel handleFetch={handleFetch} />,
		},
	];

	const items3 = [
		{
			key: '2',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
                    Delegated Service Provision Agreement
					</span>
				</div>
			),
			children: <DelegatedServicePanel handleFetch={handleFetch} />,
		},
	];

    const items4 = [
		{
			key: '3',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
                    Billing Transition Plan
					</span>
				</div>
			),
			children: <BillingTransitionPanel handleFetch={handleFetch} />,
		},
	];

	return (
		<>
			<div
				style={{
					maxWidth: '100%',
					width: '40.68756rem',
					height: '0.0625rem',
					opacity: 0.3,
					background: 'var(--Blue-grey, #7E93AE)',
				}}
				className='mt-[1.13rem]'></div>

			<div className='project_view_company_txt mt-[2.19rem]'>
				{projectObj?.accTradeName} | SSWP No: N/A
			</div>

			<div className='project_view_contact_txt mt-[1.5rem]'>
				Contact Person: {projectObj?.usrFirstName ?? 'N/A'}{' '}
				{projectObj?.usrLastName ?? ''} - {projectObj?.usrMobileNumber}
			</div>

			<div className='mb-[1.88rem] mt-[2.44rem]'>
				<Collapse
					className='collapse-white'
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items2}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items3}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

            <div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items4}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='w-full flex flex-col items-center justify-center gap-y-[2.67rem] mt-[10.23rem] mb-[5rem]'>
				{projectObj?.projStatus === 'COMMITTED' ? (
					<>
						<button disabled={saving} onClick={confirm} className='cstm-btn-2'>
							{saving ? <Spin /> : 'Send to Deal Room'}
						</button>
						<div className='w-full flex items-center justify-center gap-x-[3.67rem]'>
							<button disabled={saving} onClick={() => prev()}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='62'
									height='24'
									viewBox='0 0 62 24'
									fill='none'>
									<path
										d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
										fill='#0873B9'
									/>
								</svg>
							</button>

							<span className='panel_num_txt'>3/3</span>
						</div>
					</>
				) : (
					<>
						<div className='w-full flex items-center justify-center gap-x-[11.67rem]'>
							<button disabled={saving} onClick={() => prev()}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='62'
									height='24'
									viewBox='0 0 62 24'
									fill='none'>
									<path
										d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
										fill='#0873B9'
									/>
								</svg>
							</button>

							<span className='panel_num_txt'>3/3</span>
						</div>
					</>
				)}
			</div>
		</>
	);
}
