import { Radio } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ViewFileButton from '../../../components/ViewFileButton';

export default function SpecialMinutePanel() {
	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);

	useEffect(() => {}, [projectObj, projectPreparationDetails]);

	return (
		<>
			<div className='flex flex-col px-[2rem] pb-[2rem]'>
				<Radio.Group value={projectPreparationDetails?.wrSpecialMin}>
					<Radio value='provided'>Provided</Radio>
					<Radio className='ml-[10.19rem]' value='notProvided'>
						Not Provided
					</Radio>
				</Radio.Group>

				<div className='mt-[1.75rem]'>
					<ViewFileButton file={projectPreparationDetails?.wrSpecialMinFile} />
				</div>
			</div>
		</>
	);
}
