import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createData } from '../../../../utils';
import GradingComponent from '../../../../components/GradingComponent';

export default function WaterAssessmentDetails({ handleFetch }) {
	const { projectObj } = useSelector((state) => state.obj);
	const { projectScoreStepTwo } = useSelector((state) => state.fetch);

	const {
		projExistingOperational,
		projOtherExistingWaterSources,
		projOtherWspSswp,
	} = projectObj;

	useEffect(() => {}, [projectObj, projectScoreStepTwo]);

	const arr1 = [
		createData(
			projExistingOperational,
			'Is the project existing and operational?',
			'projExistingOperational',
			5
		),
		createData(
			projOtherExistingWaterSources,
			'Other existing sources within the water project area of operation',
			'projOtherExistingWaterSources',
			5
		),
		createData(
			projOtherWspSswp,
			'Is there a WSP or another SSWP operating within the same area of the applicant',
			'projOtherWspSswp',
			5
		),
	];

	return (
		<>
			<Row>
				<Col span={24}>
					{arr1?.map((item) => {
						return (
							<GradingComponent
								handleFetch={handleFetch}
								arrayList={projectScoreStepTwo}
								data={item.data}
								itemKey={item.itemKey}
								itemName={item.itemName}
								isFile={item.isFile}
								overalScore={item.overalScore}
							/>
						);
					})}
				</Col>
			</Row>
		</>
	);
}
