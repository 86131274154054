import { Table } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function FinalVerdictDetails() {
	const { projectObj } = useSelector((state) => state.obj);

	const { loading, projectScores } = useSelector((state) => state.fetch);

	useEffect(() => {}, [projectObj, projectScores]);

	const columns = [
		{
			title: 'Step',
			dataIndex: 'sctStep',
			render: (item) => {
				let step = item?.split('_')?.join(' ');

				return <span className='capitalize'>{step}</span>;
			},
		},
		{
			title: 'Comments',
			dataIndex: 'sctComment',
		},
		{
			title: 'Total score',
			dataIndex: 'sctTotalScore',
		},
	];

	return (
		<>
			<div className='w-full mt-[2.75rem]'>
				<Table
					loading={loading}
					pagination={{
						defaultPageSize: 7,
						hideOnSinglePage: true,
						pageSizeOptions: [7, 15, 50, 100],
					}}
					columns={columns}
					dataSource={projectScores?.filter(item => item?.sctStep !== "FINAL_VERDICT")}
				/>
			</div>
		</>
	);
}
