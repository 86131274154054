import { Col, Form, Row, Select } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { booleanData, eiaData } from '../../../utils';
import ViewFileButton from '../../../components/ViewFileButton';

const selectIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11'
		height='7'
		viewBox='0 0 11 7'
		fill='none'>
		<path
			d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
			fill='#222222'
		/>
	</svg>
);

export default function EnvironmentalPanel() {
	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);


	useEffect(() => {}, [projectObj, projectPreparationDetails]);

	return (
		<>
			<Row className='px-[2rem]'>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Item</span>
						<span className='panel_txt_header'>Details</span>
						<span className='panel_txt_header'>File</span>
					</div>

					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt'>Type of EIA Report Required</span>
						<Select
							disabled
							style={{
								width: 'fit-content',
							}}
							value={
								projectPreparationDetails?.wrEiaReportType ?? 'Choose option'
							}
							options={eiaData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							suffixIcon={selectIcon}
							className='select-dotted'
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>NEMA License available</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrNemaLicenseAvailable}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrNemaLicenseAvailable?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton
							file={projectPreparationDetails?.wrNemaLicenseFile}
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>EIA Report Provided</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrEiaReportProvided}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrEiaReportProvided?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton file={projectPreparationDetails?.wrEiaReportFile} />
					</div>

					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt'>
							Are ALL stakeholders aware of project?
						</span>
						<Select
							disabled
							style={{
								width: 'fit-content',
							}}
							value={projectPreparationDetails?.wrStakeholdersAware}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrStakeholdersAware?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>
					</div>

					<Form.Item
						className='flex flex-col mt-[2rem]'
						label='Who may object to project?'>
						<TextArea
							readOnly
							value={projectPreparationDetails?.wrProjectObjectors}
							placeholder='List stakeholders who may object...'
							className='border-blue'
							rows={5}
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
}
