import React from 'react'
import SingleBar from './SingleBar'
import { capitalize } from '../../../../utils';

const CountyPerformance = ({ countyCount, selected, clientInfo }) => {

    console.log(clientInfo, "________________________CLIENT INFO______________________");

    const CountyELement = (selected === 'Total' ? countyCount : clientInfo).map(card => (
        <SingleBar
            key={selected === 'Total' ? card.jorgClientCounty : card.jorgName}
            percent={((card.activeCustomers / card.totalCount) * 100).toFixed(0)}
            title={capitalize(selected === 'Total' ? card.jorgClientCounty : card.jorgName)}
            people={card.activeCustomers}
        />
    ))
    return (
        <div className='flex flex-col w-[50%] mt-5 p-2 bg-white'>
            <div className='text-blk p-2 font-bold text-[1rem] '> {`${selected === "Total" ? "Country " : capitalize(selected)} performance`}
            </div>
            {CountyELement}
        </div>
    )
}

export default CountyPerformance