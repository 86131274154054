import { Col, Row, Select } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { booleanData } from '../../../utils';
import ViewFileButton from '../../../components/ViewFileButton';

const selectIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11'
		height='7'
		viewBox='0 0 11 7'
		fill='none'>
		<path
			d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
			fill='#222222'
		/>
	</svg>
);

export default function DelegatedServicePanel({ handleFetch }) {
	const { projectObj, projectPreparationDetails } = useSelector(
		(state) => state.obj
	);

	useEffect(() => {}, [projectObj, projectPreparationDetails]);

	return (
		<>
			<Row className='px-[2rem]'>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt_header'>Item</span>
						<span className='panel_txt_header'>Details</span>
						<span className='panel_txt_header'>File</span>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>
							Letter of No Objection from County Government
						</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrDsCountyGovLetter}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrDsCountyGovLetter?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>

						<ViewFileButton
							file={projectPreparationDetails?.wrDsCountyGovLetterFile}
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3'>
						<span className='panel_txt'>Is SSWP in WSP area?</span>
						<Select
							disabled
							value={projectPreparationDetails?.wrDsWsspPresent}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrDsWsspPresent?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt'>Letter of No Objection from WSP?</span>
						<Select
							disabled
							style={{
								width: 'fit-content',
							}}
							value={projectPreparationDetails?.wrDsWspLetter}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrDsWspLetter?.toUpperCase() === 'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>
					</div>

					<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
						<span className='panel_txt'>Delegated SPA</span>
						<Select
							disabled
							style={{
								width: 'fit-content',
							}}
							value={projectPreparationDetails?.wrDsDelegatedSpa}
							options={booleanData?.map((item) => {
								return {
									label: item?.label,
									value: item?.value,
								};
							})}
							status={
								projectPreparationDetails?.wrDsDelegatedSpa?.toUpperCase() ===
								'NO'
									? 'error'
									: 'warning'
							}
							suffixIcon={selectIcon}
							className='select-status'
						/>
					</div>
				</Col>
			</Row>
		</>
	);
}
