import { Dropdown, Table } from 'antd';
import PageHeader from '../../components/PageHeader';
import Search from '../../components/Search';
import BreadCrumb from '../../layout/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchViableProjects } from '../../features/fetch/fetchSlice';
import { useEffect, useState } from 'react';
import { clearProjectObj, setProjectObj } from '../../features/obj/objSlice';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../utils';

const breadList = [
	{
		title: 'Home',
		href: '/',
	},
	{
		title: 'Deal Room',
        href: '/',
	},
    {
		title: 'Viable Projects',
	},
];

const usrIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='31'
		height='31'
		viewBox='0 0 31 31'
		fill='none'>
		<g clip-path='url(#clip0_1_4022)'>
			<path
				d='M15.4997 2.58301C8.36967 2.58301 2.58301 8.36967 2.58301 15.4997C2.58301 22.6297 8.36967 28.4163 15.4997 28.4163C22.6297 28.4163 28.4163 22.6297 28.4163 15.4997C28.4163 8.36967 22.6297 2.58301 15.4997 2.58301ZM15.4997 6.45801C17.6438 6.45801 19.3747 8.18884 19.3747 10.333C19.3747 12.4772 17.6438 14.208 15.4997 14.208C13.3555 14.208 11.6247 12.4772 11.6247 10.333C11.6247 8.18884 13.3555 6.45801 15.4997 6.45801ZM15.4997 24.7997C12.2705 24.7997 9.41592 23.1463 7.74967 20.6405C7.78842 18.0701 12.9163 16.6622 15.4997 16.6622C18.0701 16.6622 23.2109 18.0701 23.2497 20.6405C21.5834 23.1463 18.7288 24.7997 15.4997 24.7997Z'
				fill='#0170BD'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_4022'>
				<rect width='31' height='31' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export default function ViableProjects() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loading, viableProjects } = useSelector((state) => state.fetch);

	const [value, setvalue] = useState('');
	const [projObj, setprojObj] = useState({});

	const arrayCopy = [...viableProjects]?.filter(
		(item) =>
			String(item?.accTradeName)
				.toUpperCase()
				.includes(String(value).toUpperCase()) ||
			String(item?.accCounty)
				.toUpperCase()
				.includes(String(value).toUpperCase())
	);

	function handleValueChange(val) {
		setvalue(val);
	}

	async function handleViewAction() {
		await dispatch(setProjectObj(projObj));
		await navigate('/project-summary');
	}

	async function handlePrepareAction() {
		await dispatch(setProjectObj(projObj));
		await navigate('/project-summary/prepared-project');
	}

	async function handleFetch() {
		await dispatch(clearProjectObj());
		await dispatch(fetchViableProjects());
	}

	useEffect(() => {}, [viableProjects, value, projObj]);

	useEffect(() => {
		handleFetch();
	}, []);

	const items = [
		{
			key: '1',
			label: <div onClick={handleViewAction}>View project</div>,
		},
		{
			key: '2',
			label: <div onClick={handlePrepareAction}>View term sheet</div>,
		},
	];

	const columns = [
		{
			title: 'Project',
			dataIndex: 'accTradeName',
			render: (item) => (
				<div className='flex items-center'>
					{usrIcon}
					<span className='ml-[1.37rem]'>{item}</span>
				</div>
			),
		},
		{
			title: 'Region',
			dataIndex: 'accCounty',
		},
		{
			title: 'Application cost',
			dataIndex: 'projCost',
			render: (item) => <span>{formatMoney(item)}</span>,
		},
		{
			title: 'Action',
			render: (item) => (
				<>
					<Dropdown
						overlayClassName='notification-dropdown'
						overlayStyle={{
							minWidth: '18rem',
							width: 'auto',
							maxWidth: 'fit-content',
						}}
						onClick={(e) => {
							e?.stopPropagation();
							e.preventDefault();
							setprojObj(item);
						}}
						menu={{
							items,
						}}
						arrow
						placement='bottom'
						trigger={['click']}>
						<button className='flex items-center gap-x-[.1rem] bg-[#D6EFFF] p-[.25rem] rounded-[.5rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='5'
								height='5'
								viewBox='0 0 5 5'
								fill='none'>
								<circle
									cx='2.5'
									cy='2.5'
									r='2.5'
									transform='rotate(-90 2.5 2.5)'
									fill='#0170BD'
								/>
							</svg>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='5'
								height='5'
								viewBox='0 0 5 5'
								fill='none'>
								<circle
									cx='2.5'
									cy='2.5'
									r='2.5'
									transform='rotate(-90 2.5 2.5)'
									fill='#0170BD'
								/>
							</svg>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='5'
								height='5'
								viewBox='0 0 5 5'
								fill='none'>
								<circle
									cx='2.5'
									cy='2.5'
									r='2.5'
									transform='rotate(-90 2.5 2.5)'
									fill='#0170BD'
								/>
							</svg>{' '}
						</button>
					</Dropdown>
				</>
			),
		},
	];

	return (
		<>
			<div className='w-full flex flex-col'>
				<PageHeader header={'Viable Projects'} />
				<div className='mt-[.94rem]'>
					<BreadCrumb breadList={breadList} />
				</div>
				<div className='mt-[2.19rem]'>
					<Search handleValueChange={handleValueChange} />
				</div>
				<div className='w-full mt-[2.75rem]'>
					<Table
						loading={loading}
						pagination={{
							defaultPageSize: 7,
							hideOnSinglePage: true,
							pageSizeOptions: [7, 15, 50, 100],
						}}
						columns={columns}
						dataSource={arrayCopy}
					/>
				</div>
			</div>
		</>
	);
}
