import React, { useEffect } from "react";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../assets/img/image1.png";
import { FundProjectionScreenOutlined } from "@ant-design/icons";

export default function SideBarOpen() {
  const navigate = useNavigate();

  const { collapsed } = useSelector((state) => state.setup);

  const handleNavigation = (e) => {
    navigate(e.key);
  };

  const items = [
    {
      key: "/",
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='26'
          height='26'
          viewBox='0 0 26 26'
          fill='none'
        >
          <path
            opacity='0.35'
            d='M12.9997 12.9998C15.9912 12.9998 18.4163 10.5747 18.4163 7.58317C18.4163 4.59163 15.9912 2.1665 12.9997 2.1665C10.0081 2.1665 7.58301 4.59163 7.58301 7.58317C7.58301 10.5747 10.0081 12.9998 12.9997 12.9998Z'
            fill='#7E93AE'
          />
          <path
            d='M19.5 16.25H6.5C4.70492 16.25 3.25 17.7049 3.25 19.5C3.25 21.2951 4.70492 22.75 6.5 22.75H19.5C21.2951 22.75 22.75 21.2951 22.75 19.5C22.75 17.7049 21.2951 16.25 19.5 16.25Z'
            fill='#7E93AE'
          />
        </svg>
      ),
      label: "Home",
    },
    {
      key: "/wash-prospects",
      icon: <FundProjectionScreenOutlined />,
      label: "Wash Prospects",
    },
    {
      key: "2",
      label: "EXPRESSION OF DEMAND",
      children: [
        {
          key: "/pipelines/new-projects",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "New Projects",
        },
        {
          key: "/expression-of-demand/completed",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Complete",
        },
        {
          key: "/expression-of-demand/incomplete",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Incomplete",
        },
      ],
    },
    {
      key: "3",
      label: "PIPELINE",
      children: [
        {
          key: "/pipelines/deal-sourcing",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Deal Sourcing",
        },
      ],
    },
    {
      key: "4",
      label: "PROJECT PREPARATION",
      children: [
        {
          key: "/project-preparation/commited-projects",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Committed Projects",
        },
      ],
    },
    {
      key: "5",
      label: "DEAL ROOM",
      children: [
        {
          key: "/deal-room/viable-projects",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Viable projects",
        },
        {
          key: "/deal-room/bids",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Bids",
        },
        {
          key: "/deal-room/deals",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Deals",
        },
      ],
    },
    {
      key: "6",
      label: "PROJECTS",
      children: [
        {
          key: "/projects/loan-perfection",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Loan perfection",
        },
        {
          key: "/projects/project-development",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Project development",
        },
      ],
    },
    {
      key: "7",
      label: "IMPACT DASHBOARD",
      children: [
        {
          key: "/dashboard",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Dashboard",
        },
        {
          key: "/water-sector",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Water sector performance",
        },
        {
          key: "/monitoring",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Monitoring & Evaluation",
        },
      ],
    },
    {
      key: "8",
      label: "MY ACCOUNT ",
      children: [
        {
          key: "/account",
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                opacity='0.35'
                d='M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z'
                fill='#7E93AE'
              />
              <path
                d='M21 15V18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18V15H7.342C8.143 15 8.924 15.431 9.235 16.169C9.69 17.246 10.755 18 12 18C13.245 18 14.31 17.246 14.765 16.169C15.076 15.431 15.856 15 16.658 15H21Z'
                fill='#7E93AE'
              />
            </svg>
          ),
          label: "Account",
        },
      ],
    },
  ];

  useEffect(() => {}, [collapsed]);

  return (
    <>
      <div className='flex flex-col h-[95vh] sticky top-0 overflow-y-scroll py-[1.56rem] flex-shrink-0 w-[17.3125rem] bg-softblu'>
        <div className='flex items-end w-full '>
          <img src={image1} className='logo' alt='image2' />
        </div>

        <div className='mt-[2rem]'>
          <Menu
            onClick={handleNavigation}
            style={{
              width: "auto",
            }}
            defaultSelectedKeys={["/"]}
            defaultOpenKeys={["2", "3", "4", "5", "6", "7", "8", "9"]}
            openKeys={["2", "3", "4", "5", "6", "7", "8", "9"]}
            mode='inline'
            items={items}
            theme='light'
            inlineCollapsed={collapsed}
          />
        </div>
      </div>
    </>
  );
}
