import React from 'react'
import PageHeader from '../../../components/PageHeader'
import { Avatar, Descriptions } from 'antd';
import { useSelector } from 'react-redux';
import { formatImgPath } from '../../../utils';



const MyAccount = ({ setIsEdit }) => {

    const { user } = useSelector((state) => state.auth);

    const handleToggle = () => {
        setIsEdit(false);
    }

    const items = [
        {
            key: '1',
            label: 'Company',
            children: user.accTradeName ?? 'N/A',
        },
        {
            key: '2',
            label: 'Business Reg No',
            children: user.accRegNo ?? 'N/A',
        },
        {
            key: '3',
            label: 'Location',
            children: user.accLocation ?? 'N/A',
        },
        {
            key: '4',
            label: 'Company email',
            children: user.accEmail ?? 'N/A',
        }
    ];

    const userData = [
        {
            key: '1',
            label: 'Name',
            children: `${user.usrFirstName ?? 'N/A'} ${user.usrLastName ?? 'N/A'}`,
        },
        {
            key: '2',
            label: 'Email',
            children: user.usrEmail ?? 'N/A',
        },
        {
            key: '3',
            label: 'Phone',
            children: user.usrMobileNumber ?? 'N/A',
        }
    ];


    return (
        <div className='flex flex-col h-screen  w-[100%]'>
            <PageHeader header={'My profile'} />
            <div className='relative flex flex-row bg-[#EDF3FB] justify-end items-center h-[15%] w-full mt-10'>
                <button
                    onClick={() => setIsEdit(true)}
                    className='cstm-btn h-[40px] text-[0.875rem] mr-10 mt-10 '>Edit profile</button>
                <div className='absolute left-[5%] rounded-full bg-white top-[50%]'>
                    <Avatar
                        size={150}
                        src={formatImgPath(user.accIcon == null ? "./myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png" : user.accIcon)}
                    />
                </div>
            </div>

            <div className='flex flex-row mt-36'>
                <div className='flex flex-col w-[50%]'>
                    <Descriptions
                        column={1}
                        colon={false}
                        title='Company Information'
                        items={items}
                    />
                    <div>
                        <Descriptions
                            column={1}
                            colon={false}
                            title='User Details'
                            items={userData}
                        />
                    </div>
                </div>
                <div className='font-[700]'></div>

            </div>
        </div >
    )
}

export default MyAccount