import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function WaterAssessmentDetails() {
	const { projectObj } = useSelector((state) => state.obj);

	const {
        projExistingOperational,
        projOtherExistingWaterSources,
        projOtherWspSswp,
        projAverageVolumeDry,
        projAverageVolWet
	} = projectObj;

	useEffect(() => {}, [projectObj]);

	return (
		<>
			<Row>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt_header'>Item</span>
						<span className='px-[2rem] panel_txt_header'>Details</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Is the project existing and operational?{' '}
						</span>
						<span className='px-[2rem] panel_txt'>{projExistingOperational}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Other existing sources within the water project area of operation
						</span>
						<span className='px-[2rem] panel_txt'>{projOtherExistingWaterSources}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Is there a WSP or another SSWP operating within the same area of
							the applicant
						</span>
						<span className='px-[2rem] panel_txt'>{projOtherWspSswp}</span>
					</div>

					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Average volume abstracted per day from all sources m3/day dry
							season
						</span>
						<span className='px-[2rem] panel_txt'>{projAverageVolumeDry}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
                        Average volume abstracted per day from source m3/day wet season
						</span>
						<span className='px-[2rem] panel_txt'>{projAverageVolWet}</span>
					</div>
				</Col>
			</Row>
		</>
	);
}
