import { Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EnvironmentalPanel from './step_two_panels/EnvironmentalPanel';
import EngineeringPanel from './step_two_panels/EngineeringPanel';
import QuotationPanel from './step_two_panels/QuotationPanel';

const expandIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M1.645 0L7 5.56275L12.355 0L14 1.71255L7 9L0 1.71255L1.645 0Z'
			fill='#0170BD'
		/>
	</svg>
);

const activeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M12.355 9L7 3.43725L1.645 9L0 7.28745L7 0L14 7.28745L12.355 9Z'
			fill='#0170BD'
		/>
	</svg>
);

const mainIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='8'
		height='13'
		viewBox='0 0 8 13'
		fill='none'>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 9.34766)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 9.34766)'
			fill='#0170BD'
		/>
	</svg>
);

export default function ProjectPreparationStepTwo({ next, prev, handleFetch }) {
	const { projectObj } = useSelector((state) => state.obj);

	const [activeKey, setactiveKey] = useState(['0']);

	const onChange = (key) => {
		setactiveKey(key);
	};

	useEffect(() => {}, [projectObj]);

	const items = [
		{
			key: '0',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
                    Environmental Impact Statement
					</span>
				</div>
			),
			children: <EnvironmentalPanel handleFetch={handleFetch} />,
		},
	];

	const items2 = [
		{
			key: '1',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Engineering Design</span>
				</div>
			),
			children: <EngineeringPanel handleFetch={handleFetch} />,
		},
	];

	const items3 = [
		{
			key: '2',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
					Quotations
					</span>
				</div>
			),
			children: <QuotationPanel handleFetch={handleFetch} />,
		},
	];

	return (
		<>
			<div
				style={{
					maxWidth: '100%',
					width: '40.68756rem',
					height: '0.0625rem',
					opacity: 0.3,
					background: 'var(--Blue-grey, #7E93AE)',
				}}
				className='mt-[1.13rem]'></div>

			<div className='project_view_company_txt mt-[2.19rem]'>
				{projectObj?.accTradeName} | SSWP No: N/A
			</div>

			<div className='project_view_contact_txt mt-[1.5rem]'>
				Contact Person: {projectObj?.usrFirstName ?? 'N/A'}{' '}
				{projectObj?.usrLastName ?? ''} - {projectObj?.usrMobileNumber}
			</div>

			<div className='mb-[1.88rem] mt-[2.44rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items2}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items3}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='w-full flex items-center justify-center gap-x-[11.67rem] mt-[10.23rem] mb-[5rem]'>
				<button onClick={() => prev()}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='62'
						height='24'
						viewBox='0 0 62 24'
						fill='none'>
						<path
							d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
							fill='#0873B9'
						/>
					</svg>
				</button>

				<span className='panel_num_txt'>2/3</span>

				<button onClick={() => next()}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='68'
						height='20'
						viewBox='0 0 68 20'
						fill='none'>
						<path
							d='M50.6308 7.84059H0.379883V12.6614H50.6308V19.8926L67.3253 10.251L50.6308 0.609375V7.84059Z'
							fill='#0873B9'
						/>
					</svg>
				</button>
			</div>
		</>
	);
}
