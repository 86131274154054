import { Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOperationStaff } from '../../../../features/fetch/fetchSlice';

export default function TarrifSystemVolumetricDetails() {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);

	async function handleFetch() {
		await dispatch(fetchOperationStaff(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj]);

	useEffect(() => {
		handleFetch();
	}, []);

	const {
		projOtherV1,
		projOtherV2,
		projOtherCharge,
		projComV1,
		projComV2,
		projCommercialCharge,
		projInstV1,
		projInstV2,
		projIntitutionCharge,
		projKioskV1,
		projKioskV2,
		projKioskCharge,
		projIndV1,
		projIndV2,
		projIndividualCharge,
	} = projectObj;

	const columns = [
		{
			title: 'Type of customer',
			dataIndex: 'value1',
		},
		{
			title: 'Volume(Minimum vol(v1))',
			dataIndex: 'value2',
		},
		{
			title: 'Volumee(Minimum vol(v2))',
			dataIndex: 'value3',
		},
		{
			title: 'Charge (Ksh/m3 (Minimum charge) )',
			dataIndex: 'value4',
		},
	];

	const data = [
		{
			value1: 'Individual',
			value2: projIndV1,
			vol2: projIndV2,
			value4: projIndividualCharge,
		},
        {
			value1: 'Kiosk',
			value2: projKioskV1,
			value3: projKioskV2,
			value4: projKioskCharge,
		},
        {
			value1: 'Institution(school, HCF)',
			value2: projInstV1,
			value3: projInstV2,
			value4: projIntitutionCharge,
		},
        {
			value1: 'Commerical',
			value2: projComV1,
			value3: projComV2,
			value4: projCommercialCharge,
		},
        {
			value1: 'Others',
			value2: projOtherV1,
			value3: projOtherV2,
			value4: projOtherCharge,
		},
	];

	return (
		<>
			<div className='w-full mt-[2.75rem]'>
				<Table
					pagination={{
						defaultPageSize: 7,
						hideOnSinglePage: true,
						pageSizeOptions: [7, 15, 50, 100],
					}}
					columns={columns}
					dataSource={data}
				/>
			</div>
		</>
	);
}
