import { useSelector } from 'react-redux';
import BreadCrumb from '../layout/BreadCrumb';
import { useEffect } from 'react';
import PageHeader from './PageHeader';
import { Descriptions } from 'antd';
import { formatMoney } from '../utils';

const locationIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='28'
		height='28'
		viewBox='0 0 28 28'
		fill='none'>
		<g clip-path='url(#clip0_1_4308)'>
			<path
				d='M13.9997 2.33301C9.48467 2.33301 5.83301 5.98467 5.83301 10.4997C5.83301 16.6247 13.9997 25.6663 13.9997 25.6663C13.9997 25.6663 22.1663 16.6247 22.1663 10.4997C22.1663 5.98467 18.5147 2.33301 13.9997 2.33301ZM8.16634 10.4997C8.16634 7.27967 10.7797 4.66634 13.9997 4.66634C17.2197 4.66634 19.833 7.27967 19.833 10.4997C19.833 13.8597 16.473 18.888 13.9997 22.0263C11.573 18.9113 8.16634 13.8247 8.16634 10.4997Z'
				fill='#0873B9'
			/>
			<path
				d='M13.9997 13.4163C15.6105 13.4163 16.9163 12.1105 16.9163 10.4997C16.9163 8.88884 15.6105 7.58301 13.9997 7.58301C12.3888 7.58301 11.083 8.88884 11.083 10.4997C11.083 12.1105 12.3888 13.4163 13.9997 13.4163Z'
				fill='#0873B9'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_4308'>
				<rect width='28' height='28' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

const timeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='36'
		height='36'
		viewBox='0 0 36 36'
		fill='none'>
		<path
			d='M18 0C8.08019 0 0 8.08019 0 18C0 27.9198 8.08019 36 18 36C27.9198 36 36 27.9198 36 18C36 8.08019 27.9198 0 18 0ZM18 3.6C25.9742 3.6 32.4 10.0258 32.4 18C32.4 25.9742 25.9742 32.4 18 32.4C10.0258 32.4 3.6 25.9742 3.6 18C3.6 10.0258 10.0258 3.6 18 3.6ZM16.2 7.2V18.7453L23.9273 26.4727L26.4727 23.9273L19.8 17.2547V7.2H16.2Z'
			fill='#0170BD'
		/>
	</svg>
);

const homeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='52'
		height='51'
		viewBox='0 0 52 51'
		fill='none'>
		<g clip-path='url(#clip0_1_4328)'>
			<path
				d='M41.1663 19.7625V8.5H34.6663V14.025L25.9997 6.375L4.33301 25.5H10.833V42.5H23.833V29.75H28.1663V42.5H41.1663V25.5H47.6663L41.1663 19.7625ZM36.833 38.25H32.4997V25.5H19.4997V38.25H15.1663V21.6537L25.9997 12.0912L36.833 21.6537V38.25Z'
				fill='#0873B9'
			/>
			<path
				d='M21.666 21.25H30.3327C30.3327 18.9125 28.3827 17 25.9993 17C23.616 17 21.666 18.9125 21.666 21.25Z'
				fill='#0873B9'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_4328'>
				<rect width='52' height='51' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export default function ProjectView({ title, pageUrl }) {
	const { projectObj } = useSelector((state) => state.obj);

	useEffect(() => {}, [projectObj]);

	const {
        usrFirstName,
        usrLastName,
		usrEmail,
		accSubCounty,
		usrAltPhoneNo,
		accCounty,
		usrMobileNumber,
		accLocation,
	} = projectObj;

    let usrFullNames = usrFirstName || usrLastName ? usrFirstName + ' ' + usrLastName : "N/A"

	const items = [
		{
			key: '1',
			label: 'Contact Person',
			children: usrFullNames ?? 'N/A',
		},
		{
			key: '2',
			label: 'County',
			children: accCounty ?? 'N/A',
		},
		{
			key: '3',
			label: 'Email',
			children: usrEmail ?? 'N/A',
		},
		{
			key: '4',
			label: 'Sub County',
			children: accSubCounty ?? 'N/A',
		},
		{
			key: '5',
			label: 'Alternate Phone',
			children:
				usrAltPhoneNo || usrMobileNumber
					? usrMobileNumber + ' / ' + usrAltPhoneNo
					: 'N/A',
		},
		{
			key: '6',
			label: 'Location',
			children: accLocation ?? 'N/A',
		},
	];

	// console.log(pageUrl);
	

	const breadList = [
		{
			title: 'Home',
			href: '/',
		},
		{
			title: title,
			// href: '/#/' + pageUrl,
		},
		{
			title: projectObj?.accTradeName?.toUpperCase(),
		},
	];

	return (
		<>
			<div className='flex flex-col'>
				<PageHeader header={title} />
				<div className='mt-[.94rem]'>
					<BreadCrumb breadList={breadList} />
				</div>

				<div className='mt-[2.81rem]'>
					<div className='flex items-center gap-x-[.94rem]'>
						<span className='project_view_company_txt'>
							{projectObj?.accTradeName}
						</span>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='4'
							height='32'
							viewBox='0 0 4 32'
							fill='none'>
							<path
								d='M2 2V30'
								stroke='#0170BD'
								stroke-width='3'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>
						<div className='flex items-center'>
							{locationIcon}
							<span className='project_view_company_txt ml-[.44rem]'>
								{projectObj?.accCounty} County
							</span>
						</div>
					</div>
				</div>

				<div
					style={{
						background: 'rgba(248, 253, 255, 0.80)',
					}}
					className='mt-[1.37rem] border border-[#CFF0FC] w-full grid grid-cols-3 items-center px-[1.75rem] py-[2.56rem]'>
					<div className='flex flex-col items-center justify-center'>
						{timeIcon}
						<span className='project_view_time_txt mt-[.62rem]'>{projectObj?.projOrgPeriod}</span>
						<span className='project_view_time_txt'>service time</span>
					</div>

					<div className='flex flex-col items-center justify-center'>
						{homeIcon}
						<span className='project_view_time_txt  mt-[.62rem]'>{projectObj?.projNumberHouseholdsServed}</span>
						<span className='project_view_time_txt'>households served</span>
					</div>

					<div className='flex flex-col items-center justify-center'>
						{homeIcon}
						<span className='project_view_time_txt  mt-[.62rem]'>{projectObj?.projNumberWaterKiosks}</span>
						<span className='project_view_time_txt'>water kiosks</span>
					</div>
				</div>

				<div className='mt-[3rem] flex flex-col'>
					<span className='project_view_evaluation_header'>
						Project Valuation
					</span>
					<span className='project_view_evaluation_txt mt-[.94rem]'>
                    {formatMoney(
                        projectObj?.projCost
                            ? projectObj?.projCost
                            : projectObj?.projEstCost,
                    )}
					</span>
				</div>

				<div
					style={{
						width: '100%',
						marginTop: '2.06rem',
						height: '0.0625rem',
						opacity: 0.2,
						background: '#7E93AE',
					}}></div>

				<div className='mt-[3rem] flex flex-col w-full'>
					<span className='project_view_evaluation_header'>
						Project Summary
					</span>
					<span className='project_view_evaluation_txt max-w-full mt-[.94rem]'>
                    {projectObj?.projDesc
                        ? projectObj?.projDesc
                        : projectObj?.projDescSubProj}
					</span>
				</div>

				<div className='mt-[3rem] flex flex-col'>
					<span className='project_view_evaluation_header'>
						Individual Connections
					</span>
					<div className='flex items-center gap-x-[1.31rem] mt-[.94rem]'>
						<div className='bg-[#CFF0FC] p-[1rem] flex justify-center items-center min-w-[14.9375rem] w-fit'>
							<span className='project_view_card_txt'>Active | {projectObj?.projIndividualActiveConn}</span>
						</div>
						<div className='bg-[#F4FCFF] border border-[#CFF0FC] p-[1rem] flex justify-center items-center min-w-[14.9375rem] w-fit'>
							<span className='project_view_card_txt'>Closed | {projectObj?.projIndividualClosedConn}</span>
						</div>
					</div>
				</div>

				<div className='mt-[3.44rem] flex flex-col'>
					<span className='project_view_evaluation_header'>
						Institution or Commercial Connections
					</span>
					<div className='flex items-center gap-x-[1.31rem] mt-[.94rem]'>
						<div className='bg-[#F4FCFF] border border-[#CFF0FC] p-[1rem] flex justify-center items-center min-w-[14.9375rem] w-fit'>
							<span className='project_view_card_txt'>{projectObj?.projInstitutionCommercialConn}</span>
						</div>
					</div>
				</div>

				<div className='mt-[3.44rem] flex flex-col'>
					<div className='flex items-center mt-[.94rem]'>
						<div className='bg-[#F4FCFF] rounded-tl-[1.125rem] rounded-bl-[1.125rem] border border-[#CFF0FC] p-[1rem] flex justify-center items-center min-w-[14.9375rem] w-fit'>
							<span className='project_view_card_txt'>
								Confirmation of loan repayment capabilities
							</span>
						</div>
						<div className='bg-[#44BA41] p-[1rem] w-[9.8125rem] flex justify-center items-center'>
							<span className='project_view_card_txt !text-white'> {projectObj?.projPayCapable}</span>
							<svg
								className='ml-[1.13rem]'
								xmlns='http://www.w3.org/2000/svg'
								width='14'
								height='9'
								viewBox='0 0 14 9'
								fill='none'>
								<path
									d='M1.645 0L7 5.56275L12.355 0L14 1.71255L7 9L0 1.71255L1.645 0Z'
									fill='white'
								/>
							</svg>
						</div>
					</div>
				</div>

				<div className='mt-[3.44rem]'>
					<div className='info_desc_card !bg-[#F4FCFF]'>
						<Descriptions
							column={2}
							colon={false}
							title='Contact & Address Info'
							items={items}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
