import { Col, Row } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createData, formatPath, previewPaper } from '../../../../utils';
import GradingComponent from '../../../../components/GradingComponent';

export default function AnnualMeetingDetails({ handleFetch }) {
	const { projectObj } = useSelector((state) => state.obj);
	const { projectScoreStepOne } = useSelector((state) => state.fetch);

	const {
		projAgmMinutesDate,
		projMinAgmFile,
		projAssocArtFile,
		projSgmFile,
		projMin1File,
		projMin1Date,
		projMin2Date,
		projMin3Date,
		projMin2File,
		projMin3File,
	} = projectObj;

	useEffect(() => {}, [projectScoreStepOne, projectObj]);

	const arr1 = [
		createData(
			projMinAgmFile,
			`Minutes of last AGM held on ${moment(projAgmMinutesDate).format('LL')}`,
			'projMinAgmFile',
			10,
			true
		),
		createData(
			projAssocArtFile,
			'PDF of constitution/bylaws or articles of association',
			'projAssocArtFile',
			5,
			true
		),
		createData(
			projMin1File,
			`Minutes of First committee meeting held on ${moment(projMin1Date).format('LL')}`,
			'projMin1File',
			1,
			true
		),
		createData(
			projMin2File,
			`Minutes of Second committee meeting held on ${moment(projMin2Date).format('LL')}`,
			'projMin2File',
			1,
			true
		),
		createData(
			projMin3File,
			`Minutes of Third committee meeting held on ${moment(projMin3Date).format('LL')}`,
			'projMin3File',
			1,
			true
		),
	];

	return (
		<>
			<Row>
				<Col className='mb-[1rem]' span={24}>
					<div className='bg-[#F4FCFF] rounded-[40px] p-[24px] flex items-center gap-[2rem]'>
						<div className='flex items-center gap-[.2rem] w-auto'>
							<span className='score_text'>Annual General Meeting Date - </span>
							<span className='score_text !italic'>
								{moment(projAgmMinutesDate).format('LL')}
							</span>
						</div>

						<div className='flex items-center gap-[.2rem] w-auto'>
							<span className='score_text'>
								PDF copy of minute stating project willing to seek a loan -{' '}
							</span>
							<button
								onClick={() => previewPaper(projSgmFile)}
								className='flex items-center'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='19'
									height='13'
									viewBox='0 0 19 13'
									fill='none'>
									<path
										d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
										fill='#0170BD'
									/>
								</svg>
								{projSgmFile ? (
									<span className='ml-[.44rem] table_view_txt'>
										{formatPath(projSgmFile)}
									</span>
								) : (
									<span className='ml-[.44rem] panel_txt'>No File</span>
								)}
							</button>
						</div>
					</div>
				</Col>

				<Col span={24}>
					{arr1?.map((item) => {
						return (
							<GradingComponent
								handleFetch={handleFetch}
								arrayList={projectScoreStepOne}
								data={item.data}
								itemKey={item.itemKey}
								itemName={item.itemName}
								isFile={item.isFile}
								overalScore={item.overalScore}
							/>
						);
					})}
				</Col>
			</Row>
		</>
	);
}
