import { Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOperationStaff } from '../../../../features/fetch/fetchSlice';

export default function StaffInfoDetails() {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);

	const { loading, operationStaffs } = useSelector((state) => state.fetch);

	async function handleFetch() {
		await dispatch(fetchOperationStaff(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj, operationStaffs]);

	useEffect(() => {
		handleFetch();
	}, []);

	const columns = [
		{
			title: 'Type of staff',
			dataIndex: 'osStaff',
		},
		{
			title: 'Number',
			dataIndex: 'osNumber',
		},
		{
			title: 'Type of contract',
			dataIndex: 'osContract',
		},
	];

	return (
		<>
			<div className='w-full mt-[2.75rem]'>
				<Table
					loading={loading}
					pagination={{
						defaultPageSize: 7,
						hideOnSinglePage: true,
						pageSizeOptions: [7, 15, 50, 100],
					}}
					columns={columns}
					dataSource={operationStaffs}
				/>
			</div>
		</>
	);
}
