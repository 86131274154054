import { notification } from 'antd';
import toast from 'react-hot-toast';
import svg from './assets/svg/flag_blue.svg';
import svg1 from './assets/svg/flag_yellow.svg';
import svg2 from './assets/svg/flag_brown.svg';

export const openNotificationWithIcon = (alertObj) => {
	notification[alertObj.type]({
		duration: 7,
		placement: 'bottomLeft',
		stack: true,
		threshold: 1,
		key: alertObj.key,
		message: alertObj.title,
		description: alertObj.content,
	});
};

export const customToast = (toastObj) => {
	toast.custom((t) => (
		<div
			style={{
				border: `2px solid ${
					toastObj.bdColor === 'error'
						? '#C8001B'
						: toastObj.bdColor === 'success'
						? '#02A548'
						: '#285ff6'
				}`,
			}}
			className={`max-w-md w-full bg-white rounded-[3px] flex`}>
			<div className='flex-1 w-0 p-4'>
				<div className='flex items-start'>
					{toastObj?.img ? (
						<>
							<div className='flex-shrink-0 pt-0.5'>
								<img
									className='h-10 w-10 rounded-full'
									src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80'
									alt=''
								/>
							</div>
						</>
					) : null}

					<div className='ml-3 flex-1'>
						{toastObj?.name ? (
							<>
								<p className='mt-1 text-[21px] text-blk3 font-bold'>
									{toastObj?.name}
								</p>
							</>
						) : null}
						<p className='mt-1 text-[18px] text-blk3 font-semibold'>
							{toastObj?.content}
						</p>
					</div>
				</div>
			</div>
		</div>
	));
};

export const formatMoney = (money) => {
	let keCurrency = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'KES',
	});
	if (!money) {
		return '';
	}
	return keCurrency.format(money);
};

export const obfuscateEmail = (email) => {
	if (email?.length) {
		const [username, domain] = email?.split('@');

		if (!username || !domain) {
			throw new Error('Invalid email format');
		}
		const obfuscatedUsername = username?.substring(0, 3) + '***';
		return `${obfuscatedUsername}@${domain}`;
	} else {
		return '';
	}
};

export const formatPath = (path) => {
	if (path?.length === 0 || !path) return 'No File';
	return path?.substring(path?.lastIndexOf('_') + 1);
};

export const previewPaper = (doc) => {
	if (doc) {
		let link = formatImgPath(doc);
		if (
			getFileType(doc) == 'docx' ||
			getFileType(doc) == 'doc' ||
			getFileType(doc) == 'pdf'
		) {
			window.open(`${link}`, '_blank');
		} else {
			window.open(link, '_blank');
		}
	}
};

const getFileType = (file) => {
	return file?.substring(file?.lastIndexOf('.') + 1);
};

export const formatImgPath = (path) => {
	if (
		path === null ||
		path === '' ||
		path === undefined ||
		typeof path === 'object'
	) {
		return path;
	}

	if (!path.startsWith('./')) {
		return path;
	}
	const host = window.location.host;
	const protocol = window.location.protocol;
	// const domain = "backoffice.fundmywater.com";
	const domain = 'fundmywater.com';

	if (host === 'localhost:3000') {
		return `${protocol}//${domain}${path.replace('./', '/')}`;
	} else if (host === 'localhost:5173') {
		return `${protocol}//${domain}${path.replace('./', '/')}`;
	} else {
		return `https://${domain}${path.replace('./', '/')}`;
	}
};

export const selectPriorityData = [
	{
		label: (
			<div className='flex items-center'>
				<img src={svg} alt='user icon' />
				<span className='ml-[.55rem]'>Priority</span>
			</div>
		),
		value: 'Priority',
	},
	{
		label: (
			<div className='flex items-center'>
				<img src={svg1} alt='user icon' />
				<span className='ml-[.55rem]'>Low</span>
			</div>
		),
		value: 'Low',
	},
	{
		label: (
			<div className='flex items-center'>
				<img src={svg2} alt='user icon' />
				<span className='ml-[.55rem]'>High</span>
			</div>
		),
		value: 'High',
	},
];

export const adequacyData = [
	{
		value: 'Total resource without CF project sufficient to meet demand',
		label: 'Total resource without CF project sufficient to meet demand',
	},
	{
		value: 'Total resource only sufficient to meet demand with CF project',
		label: 'Total resource only sufficient to meet demand with CF project',
	},
	{
		value: 'Total resource not sufficient to meet demand even with CF project',
		label: 'Total resource not sufficient to meet demand even with CF project',
	},
];

export const reliabilityData = [
	{
		value: 'No seasonal change',
		label: 'No seasonal change',
	},
	{
		value: 'Seasonal change but not likely to affect BP',
		label: 'Seasonal change but not likely to affect BP',
	},
	{
		value: 'Seasonal change likely to affect supply',
		label: 'Seasonal change likely to affect supply',
	},
	{
		value: 'Seasonal change factored into BP',
		label: 'Seasonal change factored into BP',
	},
];

export const eiaData = [
	{ value: 'Choose option', label: 'Choose option' },
	{ value: 'SPR', label: 'SPR' },
	{ value: 'CPR', label: 'CPR' },
];

export const booleanData = [
	{ value: 'YES', label: 'YES' },
	{ value: 'NO', label: 'NO' },
];

export const billingSystemData = [
	{ value: 'Choose option', label: 'Choose option' },
	{ value: 'Volumetric', label: 'Volumetric' },
	{ value: 'Flat rate', label: 'Flat rate' },
];

export function formatNumberWithCommas(number) {
	return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const capitalize = (str) => {
	if (!str) return '';
	return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};

export const getInitials = (fullName) => {
	if (!fullName) return 'FMW';
	return fullName
		?.split(' ')
		?.map((word) => word[0])
		?.join('');
};

export const createData = (data, itemKey, itemName, overalScore, isFile) => {
	return {
		data,
		itemKey,
		itemName,
		overalScore,
		isFile,
	};
};
