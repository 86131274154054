import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formatPath, previewPaper } from '../../../../utils';

export default function ApplicantParticularDetails() {
	const { projectObj } = useSelector((state) => state.obj);

	const {
		projIndividualActiveConn,
		projIndividualClosedConn,
		projArea,
		projLat,
		projLong,
		projTotalPopulation,
		projAproxPopulationServed,
		projInstitutionCommercialConn,
		projNumberHouseholdsServed,
		projNumberWaterKiosks,
		projMapAreaFile,
		projKioskConnFile,
		projInstConnFile,
		projIndActiveConnMbrFile,
		projPermitFile,
		projIndActiveConnCustFile,
	} = projectObj;

	useEffect(() => {}, [projectObj]);

	return (
		<>
			<Row>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt_header'>Item</span>
						<span className='px-[2rem] panel_txt_header'>Details</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Individual active connections
						</span>
						<span className='px-[2rem] panel_txt'>{projIndividualActiveConn}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Individual closed connections
						</span>
						<span className='px-[2rem] panel_txt'>{projIndividualClosedConn}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Institution or commercial connections
						</span>
						<span className='px-[2rem] panel_txt'>{projInstitutionCommercialConn}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Number of water kiosks</span>
						<span className='px-[2rem] panel_txt'>{projNumberWaterKiosks}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Number of households served
						</span>
						<span className='px-[2rem] panel_txt'>{projNumberHouseholdsServed}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Approximate population served
						</span>
						<span className='px-[2rem] panel_txt'>{projAproxPopulationServed}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Approximate total population in service area
						</span>
						<span className='px-[2rem] panel_txt'>{projTotalPopulation}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Service provision area (km2)
						</span>
						<span className='px-[2rem] panel_txt'>{projArea}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Grid reference of office / infrastructure
						</span>
						<span className='px-[2rem] panel_txt'>Latitude: {projLat ?? "N/A"}, longitude: {projLong ?? "N/A"}</span>
					</div>

					<span className='mt-[2.75rem] px-[2rem] !text-[#0170BD] panel_txt'>
						Attachments
					</span>

					<div className='panel_card w-full grid grid-cols-2 mt-[1.69rem]'>
						<span className='px-[2rem] panel_txt'>
							PDF of map of service area(pdf)
						</span>
						<button
							onClick={() => previewPaper(projMapAreaFile)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projMapAreaFile ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projMapAreaFile)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							PDF of sample kiosks contract(pdf)
						</span>
						<button
							onClick={() => previewPaper(projKioskConnFile)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projKioskConnFile ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projKioskConnFile)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							PDF of sample Institution contract(pdf)
						</span>
						<button
							onClick={() => previewPaper(projInstConnFile)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projInstConnFile ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projInstConnFile)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							PDF of list of members or accounts(pdf)
						</span>
						<button
							onClick={() => previewPaper(projIndActiveConnMbrFile)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projIndActiveConnMbrFile ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projIndActiveConnMbrFile)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							PDF of sample customer contract(pdf)
						</span>
						<button
							onClick={() => previewPaper(projIndActiveConnCustFile)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projIndActiveConnCustFile ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projIndActiveConnCustFile)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>PDF of WRA Permit(pdf)</span>
						<button
							onClick={() => previewPaper(projPermitFile)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projPermitFile ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projPermitFile)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
				</Col>
			</Row>
		</>
	);
}
