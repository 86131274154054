


import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import { capitalize } from '../../../../utils';

// const total = data.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);

const COLORS = ['#DEEFFF', '#FEDEF3', '#C3F7DE', '#FCEBB0', '#FEDEBC', '#E6C6FF'];

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
        <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const ResponsivePieChart = ({ countyCount, selected, clientInfo }) => {

    const data = countyCount.map(item => ({
        name: item.jorgClientCounty,
        value: Number(item.activeCustomers)
    }));


    const clientData = clientInfo.map(item => ({
        name: item.jorgName,
        value: Number(item.activeCustomers)
    }))
    return (
        <div className='w-[100%]'>
            <PieChart width={400} height={300}>
                <Pie
                    data={selected === 'Total' ? data : clientData}

                    cx={120}
                    cy={200}
                    innerRadius={65}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                    labelLine={false}
                // label={renderCustomizedLabel}
                >
                    {(selected === 'Total' ? data : clientData).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}


                </Pie>
            </PieChart>
            <div className='mb-2 text-blk font-[500] text-[1rem] '>Key</div>
            <ul className='flex flex-col'>
                {(selected === 'Total' ? countyCount : clientInfo).map((entry, index) => {
                    console.log(entry, "_______________________");
                    return (
                        <li key={`legend-${index}`} className='flex mt-2'>
                            <div className='mb-4' style={{ width: 20, height: 20, backgroundColor: COLORS[index % COLORS.length], marginRight: 8 }}></div>
                            <div className='flex flex-row w-[80%] justify-between'>
                                <div>{capitalize(selected === 'Total' ? entry.jorgClientCounty : entry.jorgName)}</div>
                                <div className='flex flex-row w-[20%] justify-between items-center px-1 h-8  bg-green'>
                                    <div><svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                                        <g clip-path="url(#clip0_708_6103)">
                                            <path d="M0 8.00016L3.5 5.67537L7 8.00016V5.67537L3.5 3.35059L0 5.67537V8.00016Z" fill="#7FFF74" />
                                            <path d="M0 4.64957L3.5 2.32479L7 4.64957V2.32479L3.5 0L0 2.32479V4.64957Z" fill="#7FFF74" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_708_6103">
                                                <rect width="7" height="8" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></div>

                                    <div className='text-white'>  {(entry.activeCustomers / entry.totalCount * 100).toFixed(0)}%</div>

                                </div>
                            </div>

                        </li>
                    )
                })}
            </ul>
        </div>

    );
}

export default ResponsivePieChart;
